import { EventCard } from "components/EventCard";
import { Event, Page_Flexiblecontent_Blocks_EventBlock } from "graphql";
import Calendar from "public/icons/calendar-icon.svg";
import Clock from "public/icons/clock-icon.svg";
import AddToCalendar from "./Fragments/AddToCalendar";
import { Button } from "components/Button";
import Paws from "public/text-image-assets/paws.svg";

interface EventBlockProps extends Page_Flexiblecontent_Blocks_EventBlock {
  className?: string;
}

const EventBlock: React.FC<EventBlockProps> = ({
  className = "",
  variant,
  events,
  event,
}) => {
  const isFeatured = variant === "featured";
  const isList = variant === "list";
  const isDetails = variant === "details";

  const { title, eventOptions, content, uri } = event || {};

  const { date, startTime, endTime, link, address, multiDays } = eventOptions || {};

  const {
    city,
    country,
    state,
    stateShort,
    streetAddress,
    postCode,
    placeId,
    streetName,
  } = address || {};

  // Google Maps Directions link
  const directionsUrl =
    address &&
    `https://www.google.com/maps/dir/?api=1&destination=${streetAddress}+${city}+${stateShort}+${postCode}`;

  const shouldShowAddToCalendar =
    title &&
    date &&
    startTime &&
    endTime &&
    streetAddress &&
    city &&
    stateShort &&
    postCode;

  // Formatted date Janurary 1, 2021
  const formattedDate = new Date(date ?? ``).toLocaleString("default", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const hasMultipleDays = multiDays?.length || 0;
  const startDate = multiDays?.length ? multiDays[0]?.date : date;
  const endDate = multiDays?.length
    ? multiDays[multiDays.length - 1]?.date
    : date;

  const formattedStartDate = new Date(startDate ?? ``).toLocaleDateString(
    "default",
    {
      month: "long",
      day: "numeric",
      year: "numeric",
    }
  );
  const formattedEndDate = new Date(endDate ?? ``).toLocaleDateString(
    "default",
    {
      month: "long",
      day: "numeric",
      year: "numeric",
    }
  );

  if (isFeatured && event) {
    return <EventCard variant="featured" {...(event as Event)} />;
  }

  if (isDetails && event) {
    return (
      <div className="container relative mx-auto mt-12 max-w-5xl px-4 py-8">
        <div className={`absolute -left-16 -top-6 z-20 md:-top-12`}>
          <div className={`relative h-auto w-32`}>
            <Paws />
          </div>
        </div>
        <div
          className={`flex flex-col gap-6 bg-primary-light px-4 py-8 md:px-8 md:py-20`}
        >
          <h2 className={`text-center font-heading text-4xl font-bold`}>
            {title}
          </h2>
          {hasMultipleDays ? (
            <>
            {multiDays?.map((day, index) => (
              <div key={index} className={`flex flex-col items-center justify-center gap-6 md:flex-row xl:gap-24`}>
                <div className={`flex items-center gap-4`}>
                  <Calendar />
                  <p className={`text-center font-body text-lg`}>
                    {day?.date}
                  </p>
                </div>
                {day?.startTime && day?.endTime ? (
                  <div className={`flex items-center gap-4`}>
                    <Clock />
                    <p className={`text-center font-body text-lg`}>
                      {day?.startTime} - {day?.endTime}
                    </p>
                  </div>
                ) : null}
              </div>
            ))}
            </>
          ) : (
            <div
              className={`flex flex-col items-center justify-center gap-6 md:flex-row xl:gap-24`}
            >
              <div className={`flex items-center gap-4`}>
                <Calendar />
                <p className={`text-center font-body text-lg`}>
                  {formattedDate}
                </p>
              </div>
              {startTime && endTime ? (
                <div className={`flex items-center gap-4`}>
                  <Clock />
                  <p className={`text-center font-body text-lg`}>
                    {startTime} - {endTime}
                  </p>
                </div>
              ) : null}
            </div>
          )}
          {/* Directions */}
          {directionsUrl ? (
            <div className={`flex justify-center`}>
              <Button
                variant="secondary-outline"
                href={directionsUrl}
                target="_blank"
                className={`min-w-[200px] text-center`}
              >
                Directions
              </Button>
            </div>
          ) : null}
          <div
            className={`flex flex-col items-center justify-center gap-4 md:flex-row`}
          >
            {shouldShowAddToCalendar ? (
              <AddToCalendar
                title={title ?? ``}
                date={date ?? ``}
                content={content ?? ``}
                startTime={startTime ?? ``}
                endTime={endTime ?? ``}
                streetAddress={streetAddress ?? ``}
                city={city ?? ``}
                stateShort={stateShort ?? ``}
                postCode={postCode ?? ``}
              />
            ) : null}
            {link ? (
              <Button
                variant="secondary-outline"
                href={link?.url ?? ``}
                target={link?.target ?? ``}
                className={`min-w-[200px] text-center`}
              >
                {link?.title || `Register`}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default EventBlock;
