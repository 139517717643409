import { gql } from "@apollo/client";

export const CATS_FRAGMENT = gql`
  fragment CatsFragment on RootQueryToCatConnection {
    pageInfo {
      endCursor
      hasNextPage
      total
    }
    edges {
      node {
        id
        title
        uri
        content
        animalDetails {
          animalName
          animalNid
          animalBirthday
          animalSex
          animalUniqueId
          animalWeight
          animalIntakeDate
          animalDescription
          animalPhotoGallery {
            photo
          }
          animalCoverPhoto
        }
        catAttributes {
          nodes {
            slug
            termTaxonomyId
            name
          }
        }
        breeds {
          nodes {
            slug
            termTaxonomyId
            name
          }
        }
        patterns {
          nodes {
            slug
            termTaxonomyId
            name
          }
        }
        colors {
          nodes {
            slug
            termTaxonomyId
            name
          }
        }
        catAgeGroups {
          nodes {
            slug
            termTaxonomyId
            name
          }
        }
      }
    }
  }
`;
