import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { useContext } from "react";
import { ModalContext } from "utilities/ModalContextProvider";
import VideoIcon from "public/icons/video-icon.svg";

interface VideoProps {
  videos: {
    videoId: string;
    name: string;
  }[];
}

const VideoGallery = ({ videos }: VideoProps) => {
  const [openModal, _] = useContext(ModalContext);

  return (
    <div className={`bg-tertiary flex flex-row justify-end py-5`}>
      {videos?.map((video, index) => {
        return (
          <button
            key={index}
            className={`relative flex flex-row items-center justify-center ml-5 bg-primary rounded-full py-3 px-5`}
            onClick={() => {
              openModal({
                children: (
                  <div className={`h-fit w-full`}>
                    <LiteYouTubeEmbed
                      id={video.videoId}
                      title={`Video ${index + 1}`}
                      poster="hqdefault"
                    />
                  </div>
                ),
              });
            }}
          >
            <div className={`w-7`}>
              <VideoIcon />
            </div>
            <span className={`ml-2 font-sans text-white`}>
              Video {index + 1}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default VideoGallery;
