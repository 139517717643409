// Page templates
import frontPage from "./front-page";
import page from "./page";
import pageTransportationReservations from "./page-transportation-reservations";
import pageTaylorsWish from "./page-taylors-wish";
// Post types
import single from "./single";
import singleEvents from "./single-events";
import singleResources from "./single-resources";
import singleAnimal from "./single-animal";
import singleCat from "./single-cats";
// Archives:
import archivePosts from "./archive-post";
import archiveEvents from "./archive-event";
import archiveResources from "./archive-resource";

export default {
  "front-page": frontPage,
  page,
  "page-events": archiveEvents,
  "page-resources": archiveResources,
  "page-transportation-reservations": pageTransportationReservations,
  "page-taylors-wish": pageTaylorsWish,
  // Post types
  single,
  "single-event": singleEvents,
  "single-resource": singleResources,
  "single-animal": singleAnimal,
  "single-cats": singleCat,
  // Archives
  archive: archivePosts,
  "archive-event": archiveEvents,
  "archive-resource": archiveResources,
  // Fix for News Routing
  index: archivePosts,
};
