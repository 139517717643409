import { PostTypeSeo } from "graphql";
import { NextSeo } from "next-seo";
import { removeMark } from "utilities/removeMark";

export interface SEOProps {
  seo: PostTypeSeo;
  twitter: string;
  featuredImage?: string;
}

export default function SEO({ seo, twitter, featuredImage }: SEOProps) {
  const {
    title,
    metaDesc,
    canonical,
    metaRobotsNoindex,
    metaRobotsNofollow,
    opengraphTitle,
    opengraphSiteName,
  } = seo;

  if (!title && !metaDesc) {
    return null;
  }

  const hasImage = featuredImage ? true : false;

  const url =
    process.env.NODE_ENV === "production"
      ? `https://godsdogsrescue.org`
      : "http://localhost:3000";

  const og = new URL(
    `${url}/api/og/?title=${removeMark(title as string)}${
      metaDesc ? `&description=${removeMark(metaDesc)}` : ""
    }${hasImage ? `&image=${featuredImage as string}` : ``}`
  );

  return (
    <NextSeo
      title={removeMark(title as string)}
      description={removeMark(metaDesc as string)}
      canonical={canonical ?? ``}
      openGraph={{
        url: canonical ?? ``,
        title: removeMark(opengraphTitle as string),
        description: metaDesc as string,
        siteName:
          removeMark(opengraphSiteName as string) ?? `God's Dogs Rescue`,
        images: [
          {
            url: og.href,
            width: 1200,
            height: 627,
            alt: metaDesc as string,
          },
        ],
      }}
      twitter={{
        handle: twitter ?? `@GodsDogs`,
        site: twitter ?? `@GodsDogs`,
        cardType: `summary_large_image`,
      }}
    />
  );
}
