import React from 'react'

const SkeletonCard = () => {
  const card = `bg-gray-50 shadow-lg shadow-stone-300 rounded-lg flex flex-col w-full p-2 h-auto animate-pulse`;
  const cardImage = `w-full rounded-lg overflow-hidden`;
  const cardContent = `w-full`;
  return (
    <div className={card}>
      <div className={cardImage}>
        <div className={`h-[150px] lg:h-[331px] w-full bg-gray-300`}></div>
      </div>
      <div className={cardContent}>
        <h2 className={`my-3 h-10 rounded-md bg-gray-300`}></h2>
        <div
          className={`md:text-md flex flex-col gap-2 pb-4 font-body text-xs text-gray-500`}
        >
          <div className={`h-4 rounded-md bg-gray-300`}></div>
          <div className={`h-4 rounded-md bg-gray-300`}></div>
          <div className={`h-4 rounded-md bg-gray-300`}></div>
        </div>
        <div
          className="hidden lg:block w-full min-w-[165px] h-12 rounded-full border-2 border-secondary bg-secondary px-8 py-2 text-center font-body uppercase text-white transition duration-300 ease-in-out hover:bg-white hover:text-secondary hover:shadow-lg"
        ></div>
      </div>
    </div>
  );
}

export default SkeletonCard