import { gql, useLazyQuery } from "@apollo/client";
import { ANIMALS_FRAGMENT } from "fragments";
import { PetCard } from "components/PetCard";
import React, { Key, useEffect, useRef } from "react";
import {
  Animal,
  AnimalConnectionEdge,
  OrderEnum,
  PostObjectsConnectionOrderbyEnum,
} from "graphql";
import { SkeletonCard } from "components/SkeletonCard";

interface AnimalListProps {
  isMobile: boolean;
  sortBy: PostObjectsConnectionOrderbyEnum;
  sortOrder: OrderEnum;
  searchTerm: string;
  selectedFilters: any;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
}
interface WhereObject {
  orderby: [
    {
      field: PostObjectsConnectionOrderbyEnum;
      order: OrderEnum;
    }
  ];
  search?: string;
}
interface FilterObject {
  [key: string]: {
    name: {
      in: any[];
    };
  };
}

// Helper function to generate the where object
const generateWhereObject = (
  sortBy: PostObjectsConnectionOrderbyEnum,
  sortOrder: OrderEnum,
  searchTerm?: string
) => {
  const where: WhereObject = {
    orderby: [
      {
        field: sortBy,
        order: sortOrder,
      },
    ],
  };

  if (searchTerm) {
    where.search = searchTerm;
  }

  return where;
};

// Helper function to generate the filter object
const generateFilterObject = (selectedFilters: any) => {
  const filter: FilterObject = {};
  Object.keys(selectedFilters).forEach((key) => {
    if (selectedFilters[key].length > 0) {
      filter[key] = {
        name: {
          in: selectedFilters[key],
        },
      };
    }
  });

  return filter;
};

const GET_ALL_ANIMALS = gql`
  ${ANIMALS_FRAGMENT}
  query GetAllAnimals(
    $first: Int!
    $after: String!
    $where: RootQueryToAnimalConnectionWhereArgs
    $filter: AnimalTaxonomyFilter
  ) {
    animals(first: $first, after: $after, where: $where, filter: $filter) {
      ...AnimalsFragment
    }
  }
`;

const AnimalList = ({
  isMobile,
  sortBy,
  sortOrder,
  selectedFilters,
  searchTerm,
  setTotal,
}: AnimalListProps) => {
  const loadingRef = useRef(null);
  const first = isMobile ? 10 : 9;

  const [getAnimals, { loading, error, data, fetchMore, networkStatus }] =
    useLazyQuery(GET_ALL_ANIMALS, {
      variables: {
        first,
        after: "",
        where: generateWhereObject(sortBy, sortOrder, searchTerm),
        filter: generateFilterObject(selectedFilters),
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setTotal(data.animals.pageInfo.total);
      },
    });

  useEffect(() => {
    getAnimals();
  }, []);

  const handleLoadMore = () => {
    if (!loading) {
      fetchMore({
        variables: {
          first,
          after: data.animals.pageInfo.endCursor,
          where: generateWhereObject(sortBy, sortOrder, searchTerm),
          filter: generateFilterObject(selectedFilters),
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newAnimals = {
            ...fetchMoreResult.animals,
            edges: [...prev.animals.edges, ...fetchMoreResult.animals.edges],
          };
          return {
            ...prev,
            animals: newAnimals,
          };
        },
      });
    }
  };

  const hasNextPage = data?.animals.pageInfo.hasNextPage;
  const hasAnimals = data?.animals.edges.length > 0;

  useEffect(() => {
    if (!hasNextPage || networkStatus === 1) return;
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        handleLoadMore();
      }
    }, options);

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [hasNextPage, networkStatus]);
  return (
    <>
      {hasAnimals && data?.animals ? (
        <>
          <div
            className={`grid w-full grid-cols-2 gap-2 p-4 md:gap-6 lg:grid-cols-2 xl:grid-cols-3`}
          >
            {(data?.animals.edges ?? []).map(
              (animal: AnimalConnectionEdge, index: Key) => {
                const node = animal?.node as Animal;
                return (
                  <PetCard
                    key={`${node?.id}-${index}`}
                    variant="basic"
                    pet={{ ...node }}
                    showDetails
                  />
                );
              }
            )}
          </div>
          <div ref={loadingRef} />
        </>
      ) : (
        <>
          {loading && !error ? (
            <div
              className={`grid w-full grid-cols-2 gap-2 p-4 md:gap-6 xl:grid-cols-3`}
            >
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </div>
          ) : !error ? (
            <div
              className={`flex h-full w-full flex-col items-center justify-center`}
            >
              <h2 className={`p-20 font-heading text-2xl`}>
                No dogs found. Please try a different search term or filters.
              </h2>
            </div>
          ) : (
            <div
              className={`flex h-full w-full flex-col items-center justify-center`}
            >
              <h2 className={`p-20 font-heading text-2xl`}>
                Something went wrong. Please try again later.
              </h2>
            </div>
          )}
        </>
      )}
      {networkStatus === 3 && (
        <div
          className={`grid w-full grid-cols-2 gap-2 p-4 md:gap-6 xl:grid-cols-3`}
        >
          <SkeletonCard />
          <SkeletonCard />
          {!isMobile && <SkeletonCard />}
        </div>
      )}
    </>
  );
};

export default AnimalList;
