import { Variants } from "framer-motion";

export const ModalBgVariants: Variants = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { delayChildren: 0.1 } },
};

export const ModalBoxVariants: Variants = {
  hidden: { opacity: 0, y: "60vh" },
  show: { opacity: 1, y: 0 },
};
