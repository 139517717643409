import Counter from "./Fragments/Counter";
import { FeaturedImage } from "components/FeaturedImage";
import { Page_Flexiblecontent_Blocks_Impact } from "graphql";
import styles from "./Impact.module.css";

export interface ImpactProps extends Page_Flexiblecontent_Blocks_Impact {
  className?: string;
}

const Impact = ({
  className,
  variant,
  backgroundColor,
  hasHeadingIcon,
  title,
  headingIcon,
  impactItems,
}: ImpactProps) => {
  const hasCards = impactItems && impactItems.length > 0;

  return (
    <div
      className={`${
        className ? className : ``
      } flex bg-${backgroundColor} relative flex flex-col items-center justify-center px-2 py-4`}
    >
      {hasHeadingIcon ? (
        <FeaturedImage
          image={headingIcon}
          className={`mt-6 w-full max-w-[4rem] ${title ? "" : "mb-8"}`}
          imgClassName="w-full h-full"
        />
      ) : null}
      {title ? (
        <h2 className="mx-auto mt-4 max-w-xl text-center font-heading text-5xl leading-none text-dark">
          {title}
        </h2>
      ) : null}
      {hasCards ? (
        <div
          className={`container relative mx-auto md:px-4 xl:max-w-7xl ${
            variant === "cards"
              ? `grid auto-rows-fr grid-cols-1 gap-4 md:grid-cols-3`
              : `column flex items-center justify-center`
          }`}
        >
          {impactItems.map((card: any, index: number) => {
            const {
              impactIcon,
              impactNumber,
              isDollarAmount,
              isAbbreviated,
              subtext,
            } = card ?? {};

            return (
              <div
                key={index}
                className={`relative h-full w-full flex-col justify-start px-10 py-6 transition duration-300 ease-in-out`}
              >
                <div
                  className={`${
                    className ? className : ``
                  } relative mx-auto flex h-full w-fit flex-col items-center justify-start text-center`}
                >
                  {impactIcon ? (
                    <FeaturedImage
                      image={impactIcon}
                      className={`w-full max-w-[3rem]`}
                      imgClassName="w-full"
                    />
                  ) : null}
                  {impactNumber ? (
                    <div
                      className={`mb-2 mt-6 flex flex-row font-heading  ${
                        variant === "cards"
                          ? "font-heading text-8xl text-secondary"
                          : "text-5xl sm:text-6xl md:text-7xl lg:text-8xl"
                      }`}
                    >
                      {isDollarAmount ? `$` : null}
                      <Counter
                        variant={variant}
                        impactNumber={impactNumber}
                        isAbbreviated={isAbbreviated}
                        isDollarAmount={isDollarAmount}
                      />
                      {isAbbreviated ? `k` : null}
                    </div>
                  ) : null}
                  {subtext ? (
                    <p
                      className={`${
                        variant === "cards"
                          ? `font-body`
                          : `mt-6 font-heading text-3xl`
                      }`}
                    >
                      {subtext}
                    </p>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Impact;
