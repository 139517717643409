import { Button } from "components/Button";
import { FeaturedImage } from "components/FeaturedImage";
import { Page_Flexiblecontent_Blocks_TextImage } from "graphql";
import Paws from "public/text-image-assets/paws.svg";
import styles from "./TextImage.module.css";
export interface TextImageProps extends Page_Flexiblecontent_Blocks_TextImage {
  className?: string;
}

const TextImage = ({ className, textImageRepeater }: TextImageProps) => {
  const hasSections = textImageRepeater && textImageRepeater.length > 0;

  return (
    hasSections &&
    textImageRepeater.map((section, index) => {
      const {
        backgroundColor,
        content,
        ctaRepeater,
        hasCtas,
        ctaLayout,
        hasSidePawsIcon,
        imageSide,
        pawsIconPostion,
        sectionImage,
        paddingBottom,
        paddingTop,
        title,
        sectionId,
      } = section ?? {};

      const pTop = paddingTop ? `pt-20` : ``;
      const pBottom = paddingBottom ? `pb-20` : ``;

      const pawsClasses =
        pawsIconPostion === `top-left` && imageSide
          ? `-top-8 -left-32`
          : `-top-8 -right-24 -scale-x-100 `;

      const bgColor = `bg-${backgroundColor}-light`;

      return (
        <div
          id={sectionId as string}
          key={index}
          className={`${
            className ? className : ``
          } flex ${bgColor} relative overflow-x-clip`}
        >
          <div
            className={`mx-auto flex flex-col md:flex-row md:px-4 ${pTop} ${pBottom} ${
              bgColor === "bg-primary-light"
                ? `max-w-[1200px]`
                : `max-w-[1320px]`
            }`}
          >
            <div
              className={`align-center relative flex flex-col justify-center gap-6 px-12  py-8 md:w-1/2 ${
                imageSide ? `order-last md:order-first` : `order-last`
              }`}
            >
              {hasSidePawsIcon ? (
                <div className={`absolute ${pawsClasses} z-20`}>
                  <Paws />
                </div>
              ) : null}
              {title ? (
                <h2 className="font-heading text-5xl leading-none text-dark" dangerouslySetInnerHTML={{ __html: title }}>
                </h2>
              ) : null}
              {content ? (
                <div
                  className={`${styles.textImageContent} text-md max-w-lg font-body text-dark`}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              ) : null}
              {hasCtas && ctaRepeater ? (
                <div
                  className={`flex ${
                    ctaLayout === "vertical"
                      ? "flex-col"
                      : "flex-col lg:flex-row"
                  } gap-4`}
                >
                  {ctaRepeater.map((cta, index) => {
                    const { title, url, target } = cta?.ctaLink ?? {};

                    return (
                      <Button
                        key={index}
                        href={url ?? "#"}
                        target={target ?? "_self"}
                        className={`w-fit md:px-4`}
                        variant={cta?.type as string}
                      >
                        {title}
                      </Button>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div
              className={`flex flex-col gap-6 md:w-1/2
            ${
              imageSide
                ? `order-last md:order-last`
                : `order-last md:order-first`
            }
          `}
            >
              {sectionImage ? (
                <FeaturedImage
                  image={sectionImage}
                  className={`w-full`}
                  imgClassName={`max-h-[100%] max-w-[100%] mx-auto ${
                    bgColor === "bg-primary-light" ? `w-[95%]` : ``
                  }`}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    })
  );
};

export default TextImage;
