import { useEffect, useState } from "react";
import Search from "./Fragments/Search";
import AnimalList from "./Fragments/AnimalList";
import FilterList from "./Fragments/FilterList";
import { PostObjectsConnectionOrderbyEnum } from "graphql";

interface AvailableDogsProps {
  hasAnnouncements?: boolean;
}

// declare enum PostObjectsConnectionOrderbyEnum {
//   AnimalName = "ANIMAL_NAME",
//   AnimalBirthday = "ANIMAL_BIRTHDAY",
// }
// declare enum OrderEnum {
//   Asc = "ASC",
//   Desc = "DESC",
// }
export enum OrderEnum {
  Asc = "ASC",
  Desc = "DESC",
}

const AvailableDogs = (hasAnnouncements: AvailableDogsProps ) => {
  const [sortBy, setSortBy] = useState("ANIMAL_NAME");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [selectedFilters, setSelectedFilters] = useState({
    primaryBreed: [],
    gender: [],
    weightGroup: [],
    ageGroup: [],
    petAttribute: [],
    virtualFosterable: [],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [total, setTotal] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  const handleSortByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value as PostObjectsConnectionOrderbyEnum;
    setSortBy(selectedValue);
  };

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === OrderEnum.Asc ? OrderEnum.Desc : OrderEnum.Asc);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const hasSelectedFilters = Object.values(selectedFilters).some(
    (filter) => filter.length > 0
  );

  const handleFilterToggle = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
  };

  const SortBy = () => (
    <>
      <div className={`relative h-[48px] w-[105px]`}>
        <label
          className={`absolute left-3 top-1 text-sm font-normal`}
        >
          Sort By:
        </label>
        <select
          className={`h-full w-full appearance-none rounded-md bg-[#F4F4F4] px-0 pl-3 pt-4 font-semibold text-primary`}
          onChange={handleSortByChange}
          value={sortBy}
        >
          <option value="" disabled>
            Sort By
          </option>
          <option
            value={"ANIMAL_NAME"}
          >{`Name`}</option>
          <option
            value={"ANIMAL_BIRTHDAY"}
          >{`Age`}</option>
        </select>
        <svg
          className={`absolute right-3 top-1/2 -translate-y-1/2`}
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 24 24"
        >
          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
        </svg>
      </div>
      {sortBy ? (
        <button
          className={`relative flex h-[48px] w-[115px] flex-row items-center rounded-md bg-gray-100 pt-4`}
          onClick={handleSortOrderChange}
        >
          <div className={`absolute left-3 top-1 text-sm font-normal`}>
            Sort Order:
          </div>
          <span className={`whitespace-nowrap pl-3 font-bold text-primary`}>
            {sortOrder === "ASC" ? "Asc" : "Desc"}
          </span>
          <div className={`absolute right-3 top-1/2 -translate-y-1/2`}>
            {sortOrder === "ASC" ? "↑" : "↓"}
          </div>
        </button>
      ) : null}
    </>
  );

  const stickyTop = hasAnnouncements.hasAnnouncements
    ? "top-[10.2rem] md:top-[8.7rem] lg:top-[9.7rem]"
    : "top-[5rem] lg:top-[5.7rem]";

  return (
    <div className={`container mx-auto pb-8`}>
      <div
        className={`sticky z-20 flex w-full flex-row gap-1 border-b-[1px] border-b-slate-500 bg-white px-2 py-2 lg:flex-row lg:py-4 ${stickyTop}`}
      >
        <div
          className={`flex w-full flex-row items-center gap-4 text-center lg:flex-row`}
        >
          <h2
            className={`hidden py-3 font-heading text-2xl leading-none text-dark lg:flex lg:w-fit lg:text-5xl`}
          >
            Available Dogs
          </h2>
          <Search
            className={`mx-0 h-auto w-full rounded-md bg-[#F4F4F4] px-3 py-3 md:px-6 md:py-3 lg:rounded-md`}
            handleSearch={handleSearch}
          />
          <div className={`flex w-full items-center gap-2 md:pr-3 lg:hidden`}>
            <button
              className={`min-w-32 relative flex flex-1 items-center justify-center rounded-md bg-gray-100 p-2`}
              onClick={handleFilterToggle}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                viewBox="0 0 16 16"
                className="h-8 w-8 text-primary"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 11.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"></path>
              </svg>
              <span className={`ml-2 pr-2 font-semibold`}>Filter</span>
            </button>
            <SortBy />
          </div>
        </div>
        <div className={`hidden items-center gap-4 lg:flex`}>
          <SortBy />
        </div>
      </div>
      <div className={`block lg:grid lg:grid-cols-3and4 lg:pl-5 xl:pl-0`}>
        <div
          className={`${
            isMobileFilterOpen ? `visible opacity-100` : `invisible opacity-0`
          } fixed left-0 top-0 z-50 h-full w-full bg-white transition-opacity ease-in-out lg:visible lg:relative lg:left-auto lg:top-auto lg:z-0 lg:h-auto lg:w-auto lg:bg-transparent lg:opacity-100`}
        >
          <FilterList
            total={total}
            hasSelectedFilters={hasSelectedFilters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            isMobile={isMobile}
            isMobileFilterOpen={isMobileFilterOpen}
            setIsMobileFilterOpen={setIsMobileFilterOpen}
            handleSearch={handleSearch}
          />
        </div>
        <AnimalList
          isMobile={isMobile}
          sortBy={sortBy as PostObjectsConnectionOrderbyEnum}
          sortOrder={sortOrder as OrderEnum}
          selectedFilters={selectedFilters}
          searchTerm={searchTerm}
          setTotal={setTotal}
        />
      </div>
    </div>
  );
};

export default AvailableDogs;
