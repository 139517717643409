// components/AddToCalendar.tsx
import React from "react";
import Link from "next/link";
import { removeHtml } from "utilities/removeHtml";
import { BsGoogle, BsApple } from "react-icons/bs";
import { SiMicrosoftoutlook } from "react-icons/si";

interface AddToCalendarProps {
  title: string;
  content: string;
  date: string;
  startTime: string;
  endTime: string;
  streetAddress: string;
  city: string;
  stateShort: string;
  postCode: string;
}

const toISOString = (dateStr: any, timeStr: any) => {
  const timeParts = timeStr.match(/(\d+):(\d+)\s*(am|pm)?/i);
  if (!timeParts) {
    return null;
  }

  let hours = parseInt(timeParts[1], 10);
  if (timeParts[3]?.toLowerCase() === "pm" && hours !== 12) {
    hours += 12;
  } else if (timeParts[3]?.toLowerCase() === "am" && hours === 12) {
    hours = 0;
  }
  const minutes = parseInt(timeParts[2], 10);

  const date = new Date(dateStr);
  date.setHours(hours, minutes, 0, 0);
  return date.toISOString();
};

const formatDateForGoogle = (isoString: any) => {
  const date = new Date(isoString);
  return date.toISOString().replace(/[-:]/g, "").split(".")[0] + "Z";
};

const formatAppleDateTime = (isoString: any) => {
  return isoString.replace(/[-:]/g, "").split(".")[0];
};

const AddToCalendar: React.FC<AddToCalendarProps> = ({
  title,
  content,
  date,
  startTime,
  endTime,
  streetAddress,
  city,
  stateShort,
  postCode,
}) => {
  const isoStartDate = toISOString(date, startTime);
  const isoEndDate = toISOString(date, endTime);

  const googleStartDate = formatDateForGoogle(isoStartDate);
  const googleEndDate = formatDateForGoogle(isoEndDate);

  const appleStartDate = formatAppleDateTime(isoStartDate);
  const appleEndDate = formatAppleDateTime(isoEndDate);

  return (
    <details className={`flex flex-col`}>
      <summary
        className={`min-w-[165px] rounded-full border-2 border-secondary bg-secondary px-8 py-2 text-center font-body uppercase text-white transition duration-300 ease-in-out hover:bg-white hover:text-secondary hover:shadow-lg`}
      >
        Add to Calendar
      </summary>
      <div className={`flex justify-center gap-4 py-4 text-center`}>
        <Link
          href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${googleStartDate}/${googleEndDate}&details=${content}&location=${streetAddress} ${city} ${stateShort} ${postCode}&sf=true&output=xml`}
          target={`_blank`}
          aria-label={`Add to Google Calendar`}
          className={`w-fit rounded-full bg-white p-2 text-primary`}
        >
          <BsGoogle />
        </Link>
        <Link
          href={`data:text/calendar;charset=utf8,${encodeURIComponent(
            `BEGIN:VCALENDAR\r\nVERSION:2.0\r\nBEGIN:VEVENT\r\nDTSTART:${appleStartDate}Z\r\nDTEND:${appleEndDate}Z\r\nSUMMARY:${title}\r\nDESCRIPTION:${removeHtml(
              content as string
            )}\r\nLOCATION:${streetAddress} ${city} ${stateShort} ${postCode}\r\nEND:VEVENT\r\nEND:VCALENDAR`
          )}`}
          target={`_blank`}
          aria-label={`Add to Apple Calendar`}
          className={`w-fit rounded-full bg-white p-2 text-primary`}
        >
          <BsApple />
        </Link>
        <Link
          href={`https://outlook.office.com/owa/?path=/calendar/action/compose&startdt=${isoStartDate}&enddt=${isoEndDate}&subject=${title}&location=${streetAddress} ${city} ${stateShort} ${postCode}&body=${content}`}
          target={`_blank`}
          aria-label={`Add to Outlook Calendar`}
          className={`w-fit rounded-full bg-white p-2 text-primary`}
        >
          <SiMicrosoftoutlook />
        </Link>
      </div>
    </details>
  );
};

export default AddToCalendar;
