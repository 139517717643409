import { FeaturedImage } from "components/FeaturedImage";
import { MediaItem } from "graphql";
import Link from "next/link";
import Back from "public/icons/back-icon.svg";

export interface PostHeroProps {
  title: string;
  image: MediaItem;
  link: string;
  label: string;
}

const PostHero = ({ title, image, link, label }: PostHeroProps) => {
  return (
    <div className="container mx-auto flex max-w-5xl  flex-col gap-6 px-2 pt-4 md:px-4 ">
      <Link href={link} className="flex flex-row gap-2 font-body">
        <Back /> Back to {label}
      </Link>
      <h1 className="font-display font-heading text-3xl font-bold md:text-4xl lg:text-5xl xl:text-6xl ">
        {title}
      </h1>
      <FeaturedImage
        image={image}
        className={`max-w-96 flex aspect-video w-full overflow-hidden rounded-md`}
        imgClassName="w-full h-auto object-cover object-center rounded-md"
        priority
      />
    </div>
  );
};

export default PostHero;
