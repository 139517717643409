import localFont from "next/font/local";

interface LoadingProps {
  className?: string;
  type?: string;
}

const GOOD_DOG = localFont({
  src: "../../pages/GoodDogNew.woff2",
  variable: "--font-heading",
});

const Loading = ({ className, type }: LoadingProps) => {
  if (type === "page") {
    return (
      <div
        className={`flex h-screen flex-col items-center justify-center ${GOOD_DOG.variable} gap-4`}
      >
        <div className="h-32 w-32 animate-spin rounded-full border-b-8 border-primary dark:border-gray-200"></div>
        <h1 className="mt-4 font-heading text-6xl text-primary">Loading...</h1>
      </div>
    );
  }

  return (
    <div role="status" className="mx-auto max-w-lg animate-pulse">
      <div className="mx-auto mb-4 h-6 w-48 rounded-xl bg-gray-200 dark:bg-gray-700"></div>
      <div className="mb-2.5 h-4 max-w-[360px] rounded-xl bg-gray-200 dark:bg-gray-700"></div>
      <div className="mb-2.5 h-4 rounded-xl bg-gray-200 dark:bg-gray-700"></div>
      <div className="mb-2.5 h-4 max-w-[330px] rounded-xl bg-gray-200 dark:bg-gray-700"></div>
      <div className="mb-2.5 h-4 max-w-[300px] rounded-xl bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-4 max-w-[360px] rounded-xl bg-gray-200 dark:bg-gray-700"></div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
export default Loading;
