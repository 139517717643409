import {
  AcfLink,
  Acf_GoogleMap,
  MediaItem,
  PostTypeSeo,
  RootQueryToMenuItemConnection,
  SeoSocial,
  RootQueryToAnimalConnectionEdge,
} from "graphql";
import { Main, SEO } from "features";
import { ModalContextProvider } from "utilities/ModalContextProvider";

import { Header, Footer, Sidebar } from "components";

import localFont from "next/font/local";
import dynamic from "next/dynamic";
// Pet Carousel Block:
const PetCarousel = dynamic(
  () => import("components/PetCarousel/PetCarousel"),
  {
    ssr: true,
  }
);
// PostsBlock:
const PostsBlock = dynamic(() => import("components/PostsBlock/PostsBlock"), {
  ssr: true,
});

const GOOD_DOG = localFont({
  src: "../../pages/GoodDogNew.woff2",
  variable: "--font-heading",
});

const LIGURINO = localFont({
  src: [
    {
      path: "../../pages/LigurinoRg-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../pages/LigurinoRg-Bold.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../pages/LigurinoRg-Italic.woff2",
      weight: "400",
      style: "italic",
    },
  ],
  variable: "--font-body",
});

export interface LayoutProps {
  children: React.ReactNode;
  headerMenuItems: RootQueryToMenuItemConnection;
  footerMenuItems: RootQueryToMenuItemConnection;
  title?: string;
  description: string;
  logo: MediaItem;
  logoAlt: MediaItem;
  logoWhite: MediaItem;
  seo: PostTypeSeo;
  cta: AcfLink;
  phoneNumber: AcfLink;
  address: Acf_GoogleMap;
  email: AcfLink;
  twitterUser: string;
  social: SeoSocial;
  turnOnAnnouncements?: boolean;
  announcements?: any[];
  useSidebar?: boolean;
  donateWidget?: boolean;
  dogsWidget?: boolean;
  animals?: RootQueryToAnimalConnectionEdge[];
  topBorder?: boolean;
  relatedAnimals?: any[];
  isAnimalSingle?: boolean;
  adoptionLink?: string;
  animalName?: string;
  animalId?: number;
  latestPosts?: boolean;
  seoImage?: string;
  isVirtuallyFosterable?: boolean;
}

const Layout = ({
  children,
  headerMenuItems,
  footerMenuItems,
  cta,
  title,
  description,
  seo,
  logo,
  logoAlt,
  logoWhite,
  phoneNumber,
  address,
  email,
  twitterUser,
  social,
  turnOnAnnouncements,
  announcements,
  useSidebar,
  donateWidget,
  dogsWidget,
  animals,
  topBorder,
  relatedAnimals,
  isAnimalSingle,
  adoptionLink,
  animalName,
  animalId,
  latestPosts,
  seoImage,
  isVirtuallyFosterable,
}: LayoutProps) => {
  const borderStyle = topBorder ? "pt-5" : "";
  return (
    <ModalContextProvider>
      <div className={`${LIGURINO.variable} ${GOOD_DOG.variable}`}>
        {seo ? (
          <SEO seo={seo} twitter={twitterUser} featuredImage={seoImage} />
        ) : null}
        <Header
          menuItems={headerMenuItems.nodes}
          logo={logo}
          logoAlt={logoAlt}
          pageTitle=""
          cta={cta}
          turnOnAnnouncements={turnOnAnnouncements as boolean}
          announcements={announcements}
          topBorder={topBorder as boolean}
          social={social}
        />
        {useSidebar ? (
          <Main
            className={`max-w-[1400px] grid-cols-[100%] mx-auto grid w-full lg:grid-cols-4and3 ${borderStyle}`}
          >
            <div className={`h-fit w-full px-4`}>{children}</div>
            <div className={`z-10 h-full w-full`}>
              <Sidebar
                animals={animals as any[]}
                dogsWidget={dogsWidget as boolean}
                donateWidget={donateWidget as boolean}
                isAnimalPage={isAnimalSingle}
                adoptionLink={adoptionLink}
                animalName={animalName}
                animalId={animalId}
                isVirtuallyFosterable={isVirtuallyFosterable}
              />
            </div>
          </Main>
        ) : (
          <Main className={`main`}>{children}</Main>
        )}
        {relatedAnimals ? (
          <PetCarousel variant="featured" title="" pets={relatedAnimals} />
        ) : null}
        {latestPosts ? (
          <PostsBlock useLatestPosts backgroundColor={`primary-light`} />
        ) : null}
        {/* <Main className={`main`}>{children}</Main> */}
        <Footer
          menuItems={footerMenuItems.nodes}
          cta={cta}
          phoneNumber={phoneNumber}
          logo={logoWhite}
          address={address}
          email={email}
          social={social}
        />
      </div>
    </ModalContextProvider>
  );
};

export default Layout;
