import React from "react";

interface FilterProps {
  category: string;
  filters: any;
  onChange: any;
  selectedFilters: any;
  hasSelectedFilters: boolean;
}
const Filter = ({
  category,
  filters,
  onChange,
  selectedFilters,
  hasSelectedFilters,
}: FilterProps) => {
  if (!hasSelectedFilters) {
    const inputs = document.querySelectorAll(
      `input[type="checkbox"][data-filter="${category}"]`
    ) as NodeListOf<HTMLInputElement>;
    inputs.forEach((input) => {
      input.checked = false;
    });
  }

  const isChecked = (filter: string) => {
    return selectedFilters[category]?.includes(filter);
  };

  const isToggle =
    category === "gender" ||
    category === "ageGroup" ||
    category === "weightGroup";
  const toggleLabelClass = isToggle
    ? "bg-gray-100 p-2 lg:bg-transparent lg:p-0"
    : "";
  const toggleCheckmarkClass = isToggle
    ? "absolute left-0 lg:relative lg:mr-2 lg:h-6 lg:w-6 lg:rounded-full lg:border-2 lg:border-primary lg:bg-white h-full w-full"
    : "relative mr-2 h-6 w-6 rounded-full border-2 border-primary bg-white";

  const toggleCheckClass = isToggle ? `hidden lg:block` : `block`;

  const toggleNameClass = isToggle
    ? `relative z-10 peer-checked:text-white lg:peer-checked:text-black lg:z-0`
    : `relative`;

  return filters.map((taxonomy: any, index: number) => (
    <label
      key={`filter-${taxonomy.slug}-${index}`}
      htmlFor={`${taxonomy.slug}-${index}`}
      className={`relative flex items-center gap-2 font-body ${toggleLabelClass}`}
    >
      <input
        key={`filter-${taxonomy.slug}-${index}`}
        type="checkbox"
        id={`${taxonomy.slug}-${index}`}
        name={taxonomy.slug}
        value={taxonomy.name}
        data-filter={category}
        className="peer absolute h-8 w-8 opacity-0"
        checked={isChecked(taxonomy.name)}
        onChange={onChange}
      />
      <div
        className={`flex flex-shrink-0 items-center justify-center focus-within:border-primary hover:cursor-pointer ${toggleCheckmarkClass}`}
      >
        <svg
          className="pointer-events-none hidden h-3 w-3 fill-current text-white"
          version="1.1"
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g className={toggleCheckClass} fill="none" fillRule="evenodd">
            <g transform="translate(-9 -11)" fill="#fff" fillRule="nonzero">
              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>
      </div>
      <span className={toggleNameClass}>{taxonomy.name}</span>
    </label>
  ));
};

export default Filter;
