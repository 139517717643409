import { SeoSocial } from "graphql";
import { BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import { FaFacebookF, FaYoutube } from "react-icons/fa";

export interface SocialsProps {
  socials: SeoSocial;
  type?: string;
}

interface SocialIconProps {
  social: string;
  className?: string;
}

const SocialIcon = ({ social, className }: SocialIconProps) => {
  if (!social) return null;
  // contains instagram
  if (social?.includes("instagram")) {
    return <BsInstagram className={className} />;
  }
  // contains youtube
  if (social.includes("youtube")) {
    return <FaYoutube className={className} />;
  }
  // contains linkedin
  if (social.includes("linkedin")) {
    return <BsLinkedin className={className} />;
  }

  return null;
};

const Socials = ({ socials, type }: SocialsProps) => {

  let iconBorder = "";
  let iconClass = "";
  if(type === "dark") {
    iconBorder = "flex flex-row items-center rounded-full p-2 border-2 border-dark hover:border-secondary hover:bg-secondary-light transition duration-300 ease-in-out";
    iconClass = "text-dark hover:text-brand-gray";
  } else {
    iconBorder = "flex flex-row items-center rounded-full bg-white p-2 hover:bg-secondary-light";
    iconClass = "text-primary";
  }

  return (
    <div className={`flex flex-row items-center gap-3`}>
      {/* Facebook */}
      {socials.facebook && socials.facebook.url ? (
        <a
          href={socials.facebook.url}
          target="_blank"
          rel="noopener noreferrer"
          className={`${iconBorder}`}
          aria-label={`Facebook`}
        >
          <FaFacebookF className={`text-lg ${iconClass}`} />
        </a>
      ) : null}
      {/* Twitter */}
      {socials.twitter && socials.twitter.username ? (
        <a
          href={`https://twitter.com/${socials.twitter.username}`}
          target="_blank"
          rel="noopener noreferrer"
          className={`${iconBorder}`}
          aria-label={`Twitter`}
        >
          <BsTwitter className={`text-lg ${iconClass}`} />
        </a>
      ) : null}
      {/* otherSocials */}
      {socials.otherSocials && socials.otherSocials.length > 0
        ? socials.otherSocials.map((social, index) => {
            return (
              <a
                key={index}
                target="_blank"
                href={social as string}
                rel="noopener noreferrer"
                className={`${iconBorder}`}
                aria-label={`${social} link`}
              >
                <SocialIcon social={social as string} className={`text-lg ${iconClass}`} />
              </a>
            );
          })
        : null}
    </div>
  );
};

export default Socials;
