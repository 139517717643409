import { setConfig } from "@faustwp/core";
import templates from "./wp-templates";
import possibleTypes from "possibleTypes";
import { UploadPlugin } from "plugins/UploadPlugin";
/**
 * @type {import('@faustwp/core').FaustConfig}
 **/

if (!process.env.NEXT_PUBLIC_WORDPRESS_URL) {
  console.error(
    "You must provide a NEXT_PUBLIC_WORDPRESS_URL environment variable, did you forget to load your .env.local file?"
  );
}

export default setConfig({
  templates,
  experimentalPlugins: [new UploadPlugin()],
  // experimentalToolbar: true,
  possibleTypes,
});
