import React from "react";
import Paws from "public/text-image-assets/paws.svg";

interface PetDetailsProps {
  primaryBreed: string;
  secondaryBreed: string;
  sex: string;
  weight: string;
  age: string;
}

const PetDetails = ({
  primaryBreed,
  secondaryBreed,
  sex,
  weight,
  age,
}: PetDetailsProps) => {
  return (
    <>
      <div className="relative mt-6 w-full rounded-md bg-primary-light p-10">
        <div
          className={`absolute -left-32 -top-20 z-20 hidden scale-75 md:flex`}
        >
          <Paws />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="border-b-1 border-t-1 border-black py-4 text-center md:border-b-0 md:border-l-1 md:border-r-1 md:border-t-0 md:px-4 md:py-0">
            <h2 className="font-heading text-3xl">Breed</h2>
            <p className="mt-2 text-xl">
              {primaryBreed} {secondaryBreed && ` / ${secondaryBreed}`}
            </p>
          </div>
          <div className="border-b-1 border-black py-4 text-center md:border-b-0 md:border-r-1 md:px-4 md:py-0">
            <h2 className="font-heading text-3xl">Sex</h2>
            <p className="mt-2 text-xl">{sex}</p>
          </div>
          {/* <div className="border-b-1 border-black py-4 text-center md:border-b-0 md:border-r-1 md:px-4 md:py-0">
            <h2 className="font-heading text-3xl">Weight</h2>
            <p className="mt-2 text-xl">{weight}</p>
          </div> */}
          <div className="border-b-1 border-black py-4 text-center md:border-b-0 md:border-r-1 md:px-4 md:py-0">
            <h2 className="font-heading text-3xl">Age</h2>
            <p className="mt-2 text-xl">{age}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PetDetails;
