import { FeaturedImage } from "components/FeaturedImage";
import { Post } from "graphql";
import Link from "next/link";
import { removeHtml } from "utilities/removeHtml";

export interface PostCardProps {
  post: Post;
  className?: string;
  variant?: string;
}

const PostCard = ({ post, className, variant }: PostCardProps) => {
  const { title, excerpt, uri, featuredImage, date, postOptions } = post;
  const { isExternal, externalLink } = postOptions ?? {};
  const isStacked = variant === `stacked`;

  const formattedDate = new Date(date ?? ``).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div
      className={`${className ? className : ``} flex ${
        isStacked
          ? `w-full max-w-full flex-col md:max-w-1/3`
          : `w-full flex-col md:flex-row`
      } mx-auto gap-12 p-6 text-primary`}
    >
      {featuredImage ? (
        <div
          className={`flex flex-col ${
            isStacked ? `w-full` : `w-full md:w-1/3`
          }`}
        >
          <FeaturedImage
            image={featuredImage?.node}
            className={`flex aspect-video w-full overflow-hidden object-cover`}
            imgClassName="h-auto w-full object-cover object-center"
          />
        </div>
      ) : null}
      <div
        className={`flex flex-col gap-6 ${
          isStacked ? `w-full` : `w-full md:w-2/3`
        }`}
      >
        <h2 className={`font-heading text-4xl font-bold text-dark`}>{title}</h2>
        {date ? (
          <p className={`font-body text-sm text-dark`}>{formattedDate}</p>
        ) : null}
        {excerpt ? (
          <div
            className={`font-body text-sm text-dark`}
            dangerouslySetInnerHTML={{ __html: removeHtml(excerpt) }}
          />
        ) : null}
        <Link
          href={(isExternal ? externalLink : uri) as string}
          className={`font-body text-sm uppercase text-primary underline`}
        >
          {`Learn More >`}
        </Link>
      </div>
    </div>
  );
};

export default PostCard;
