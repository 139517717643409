import { Button } from "components/Button";
import DonationForm from "features/DonationForm/DonationForm";
import { Key } from "react";
import { PetCard } from "../PetCard";
import {
  Animal,
  AnimalConnectionEdge,
  RootQueryToAnimalConnectionEdge,
} from "graphql";

export interface SidebarProps {
  dogsWidget: boolean;
  donateWidget: boolean;
  animals: RootQueryToAnimalConnectionEdge[];
  isAnimalPage?: boolean;
  adoptionLink?: string;
  animalName?: string;
  animalId?: number;
  isVirtuallyFosterable?: boolean;
}

const Sidebar = ({
  animals,
  donateWidget,
  dogsWidget,
  isAnimalPage,
  adoptionLink,
  animalName,
  animalId,
  isVirtuallyFosterable,
}: SidebarProps) => {
  return (
    <aside className={`sticky top-28 flex flex-col gap-4 p-4`}>
      {isAnimalPage ? (
        <div
          className={`flex flex-col items-center gap-2 bg-primary-light px-4 py-8`}
        >
          <p className={`font-heading text-2xl`}>Ready to meet {animalName}?</p>
          <Button
            variant="primary"
            className={`w-full whitespace-nowrap`}
            href={adoptionLink}
            target="_blank"
          >
            Apply for Adoption
          </Button>

          {isVirtuallyFosterable ? (
            <>
              <div className={`h-[1px] bg-[#707070] my-4 w-full`} />
              <p className={`font-heading text-2xl`}>
                Ready to Virtually Foster?
              </p>
              <Button
                variant="secondary-outline"
                className={`w-full whitespace-nowrap text-center lg:px-4`}
                href={`/virtual-foster?animalId=${animalId}&animalName=${animalName}`}
                target="_self"
              >
                Become Virtual Foster
              </Button>
            </>
          ) : null}
        </div>
      ) : null}
      {dogsWidget ? (
        <div className={`bg-primary-light px-4 py-8`}>
          <p
            className={`border-b-[3px] mb-6 pb-3 text-center font-heading text-3xl`}
          >
            Adopt A Dog
          </p>
          <div
            className={`flex flex-col gap-4 min-[728px]:flex-row min-[1080px]:flex-col`}
          >
            {animals?.map((animal: AnimalConnectionEdge, index: Key) => {
              const node = animal?.node as Animal;
              return (
                <PetCard
                  key={`${node?.id}-${index}`}
                  variant="basic"
                  pet={node}
                />
              );
            })}
          </div>
        </div>
      ) : null}
      {donateWidget ? (
        <div className={`bg-primary-light px-4 py-8`}>
          <p className={`mb-0 text-center font-heading text-5xl`}>Donate</p>

          <div className={`h-[1px] bg-[#707070] my-4 w-full`} />

          <DonationForm variant="sidebar" />
        </div>
      ) : null}
    </aside>
  );
};

export default Sidebar;
