import { Button } from "components/Button";
import { MediaItem, Page_Flexiblecontent_Blocks_Hero } from "graphql";
import { FeaturedImage } from "components/FeaturedImage";
import DonateHero from "./Fragments/DonateHero";
import PostHero from "./Fragments/PostHero";
import SideGraphic from "./Fragments/SideGraphic";
import Image from "next/image";

export interface HeroProps extends Page_Flexiblecontent_Blocks_Hero {
  className?: string;
}

const Hero = ({
  className,
  title,
  subtitle,
  content,
  ctas,
  hasCtas,
  leftGraphic,
  rightGraphic,
  variant,
  oneTime,
  monthly,
  heroIcon,
  featuredImage,
  featuredImageMobile,
}: HeroProps) => {
  const isBasic = variant === "basic";
  const isPrimary = variant === "primary";
  const isSecondary = variant === "secondary";
  const isDonate = variant === "donate";
  const isBlog = variant === "blogs";
  const isEvent = variant === "event";
  const isResources = variant === "resources";
  const isImage = variant === "full-img";

  const hasBottomBorder = isPrimary || isBasic || isSecondary || isDonate || isEvent || isResources;

  const isDogGroups =
    leftGraphic === "goldenGroup" ||
    leftGraphic === "terrierGroup" ||
    rightGraphic === "goldenGroup" ||
    rightGraphic === "terrierGroup";

  // Donate Hero
  if (isDonate && monthly && oneTime) {
    return (
      <DonateHero
        title={title ? title : null}
        monthly={monthly}
        oneTime={oneTime}
      />
    );
  }
  // Event Hero
  if (isEvent) {
    return (
      <PostHero
        title={title ? title : ""}
        image={featuredImage as MediaItem}
        link={`/events`}
        label={`Events`}
      />
    );
  }
  // Blog Hero
  if (isBlog) {
    return (
      <PostHero
        title={title ? title : ""}
        image={featuredImage as MediaItem}
        link={`/news/`}
        label={`News`}
      />
    );
  }
  // Resources Hero
  if (isResources) {
    return (
      <PostHero
        title={title ? title : ""}
        image={featuredImage as MediaItem}
        link={`/resources/`}
        label={`Resources`}
      />
    );
  }

  let svgClassLeft;
  let sideGraphicLeft;
  if (isBasic) {
    svgClassLeft =
      "-left-8 xs:-left-20 h-full max-w-full xs:max-w-[60%] md:-left-28 lg:-left-8 2xl:left-0 basicSvg";
  } else if (isDogGroups) {
    svgClassLeft =
      "-left-4 h-full max-w-[50%] self-start sm:-left-12 md:-left-16 2xl:left-0 dogGrpSvg";
  } else {
    svgClassLeft =
      "-left-20 h-full max-w-[50%] self-start xs:-left-12 md:-left-16 2xl:left-0 allSvg";
  }
  if (isBasic) {
    sideGraphicLeft = "relative -bottom-[4px] h-full max-h-full basicSvg";
  } else if (leftGraphic === "volunteerWalker") {
    sideGraphicLeft = "h-full max-h-[190px] md:max-h-[300px] volSvg";
  } else if (isDogGroups) {
    sideGraphicLeft =
      "absolute -bottom-[4px] h-[250px] max-h-[35vw] xs:max-h-[30vw] md:max-h-[75%] lg:h-[300px] dogGrpSvg";
  } else {
    sideGraphicLeft =
      "absolute -bottom-[7px] h-[140px] max-h-[47vw] sm:max-h-[42%] sm:h-[190px] md:max-h-[55%] lg:max-h-full lg:h-[300px] allSvg";
  }

  let svgClassRight;
  let sideGraphicRight;
  if (isBasic) {
    svgClassRight =
      "-right-20 h-full hidden xs:flex max-w-[60%] md:-right-28 md:justify-end lg:-right-8 2xl:right-0 basicSvg";
  } else if (isDogGroups) {
    svgClassRight =
      "-right-14 h-full max-w-[50%] justify-end self-end sm:-right-20 md:-right-16 2xl:right-0 dogGrpSvg";
  } else {
    svgClassRight =
      "-right-20 h-full max-w-[50%] justify-end self-end xs:-right-12 md:-right-16 2xl:right-0 allSvg";
  }
  if (isBasic) {
    sideGraphicRight =
      "relative -bottom-[4px] h-full max-h-full -scale-x-100 basicSvg";
  } else if (rightGraphic === "volunteerWalker") {
    sideGraphicRight = "h-full max-h-[190px] md:max-h-[300px] volSvg";
  } else if (isDogGroups) {
    sideGraphicRight =
      "absolute -bottom-[4px] h-[250px] max-h-[35vw] xs:max-h-[30vw] -scale-x-100 md:max-h-[75%] lg:h-[300px] dogGrpSvg";
  } else {
    sideGraphicRight =
      "absolute -bottom-[4px] h-[140px] max-h-[47vw] sm:max-h-[42%] -scale-x-100 sm:h-[190px] md:max-h-[55%] lg:max-h-full lg:h-[300px] allSvg";
  }

  let rightGraphicValue;
  if (rightGraphic === "volunteerWalker") {
    rightGraphicValue = "volunteerWalkerRight";
  } else {
    rightGraphicValue = rightGraphic;
  }

  // blogs

  return (
    <div
      className={`${className ? className : ``} ${
        hasBottomBorder  ? `border-b-[29px] border-primary` : ``
      } ${isImage ? `imgHero grid justify-center bg-gradient-to-b from-white to-[#c8cfd0] to-50% md:max-h-[650px] overflow-hidden` : `relative z-0 overflow-x-clip`} relative`}
    >
      <div
        className={`${isPrimary ? `min-h-[400px] md:min-h-[450px]` : `h-full md:max-h-[650px]`} ${
          (isBasic || isImage) ? "pb-0" : "pb-28 md:pb-40 lg:pb-0"
        } mx-auto flex flex-col ${
          !isBlog ? `items-center text-center md:flex-row` : ``
        } ${isImage ? `imgHeroCont relative z-[1] w-[100vw] max-w-[1320px] justify-start max-md:bg-gradient-to-br max-md:from-[#0000005e] max-md:to-50% max-md:to-transparent` : `relative z-0 max-w-[1320px]`}`}
        style={{ gridArea: "1/1" }}
      >
        {leftGraphic && !isBlog && !isImage ? (
          <div
            className={`${svgClassLeft} absolute -bottom-[4px] flex w-full justify-start`}
          >
            <SideGraphic
              className={`${sideGraphicLeft}`}
              graphic={isBasic ? "" : leftGraphic}
            />
          </div>
        ) : null}
        <div
          className={`${isBasic ? "py-12" : " py-8"} ${
            !isBlog ? `md:w-1/2` : `flex flex-col gap-4 pb-0`
          } ${isImage ? `md:text-left max-md:mt-6` : `mx-auto`} container z-10 w-full px-4`}
        >
          {heroIcon && isImage ? (
            <FeaturedImage
              image={heroIcon}
              className={`flex min-w-[250px] max-w-[50%] md:max-w-[400px] w-full max-md:mx-auto items-center justify-center overflow-hidden`}
              imgClassName="w-full"
            />
          ) : null}
          {title ? (
            <>
            {isImage ?
              <div
                className={`text-white font-heading drop-shadow-lg text-4xl lg:text-5xl xl:text-6xl leading-none`}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            :
              <h1 className={`${isImage ? `text-secondary` : `text-dark`} font-heading text-4xl lg:text-5xl xl:text-6xl leading-none`}>
                {title}
              </h1>
            }
            </>
            
          ) : null}
          {subtitle ? (
            <div
              className={`font-heading text-2xl lg:text-3xl xl:text-4xl ${isImage ? `text-white drop-shadow-lg` : `text-dark`}`}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          ) : null}
          {content && (
            <div
              className={`font-body text-lg mt-4 ${isImage ? `text-white` : `text-dark`}`}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          {hasCtas ? (
            <div className={`${isImage ? `` : `justify-center`} mt-8 flex flex-col items-center space-y-4 md:flex-row md:space-x-4 md:space-y-0`}>
              {ctas?.map(({ link, type }: any) => (
                <Button
                  key={link.title}
                  href={link?.url}
                  target={link?.target}
                  variant={type}
                  className="w-fit"
                >
                  {link?.title}
                </Button>
              ))}
            </div>
          ) : null}
          {featuredImage && isBlog && !isImage ? (
            <FeaturedImage
              image={featuredImage}
              className={`flex max-h-[500px] w-full items-center justify-center overflow-hidden rounded-md`}
              imgClassName="w-full rounded-md"
            />
          ) : null}
        </div>
        {rightGraphic && !isBlog && !isImage ? (
          <div className={`${svgClassRight} absolute -bottom-[4px] flex w-full`}>
            <SideGraphic
              className={`${sideGraphicRight}`}
              graphic={`${isBasic ? "" : rightGraphicValue}`}
            />
          </div>
        ) : null}
      </div>
      {isImage && featuredImage && (
          <>
          <Image
            src={featuredImage?.sourceUrl as string}
            alt={featuredImage?.altText as string}
            width={featuredImage?.mediaDetails?.width as number}
            height={featuredImage?.mediaDetails?.height as number}
            className={`max-md:hidden object-cover object-right-bottom w-[100vw] h-full`}
            style={{ gridArea: "1/1" }}
          />
          <Image
            src={featuredImageMobile?.sourceUrl as string}
            alt={featuredImageMobile?.altText as string}
            width={featuredImageMobile?.mediaDetails?.width as number}
            height={featuredImageMobile?.mediaDetails?.height as number}
            className={`md:hidden object-cover object-bottom w-[100vw] h-full`}
            style={{ gridArea: "1/1" }}
          />
          </>
        )}
    </div>
  );
};

export default Hero;
