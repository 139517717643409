import React from "react";

interface PetAttributesProps {
  attributes: {
    nodes: {
      id: string;
      name: string;
    }[];
  };
}

const PetAttributes = ({ attributes }: PetAttributesProps) => {
  const hasAttributes = attributes?.nodes.length > 0;
  if (!hasAttributes) return null;
  return (
    <div className="py-10">
      <h2 className="text-center font-heading text-6xl">Attributes</h2>
      <div className="grid grid-cols-1 py-4 md:grid-cols-2 lg:grid-cols-3">
        {attributes?.nodes.map((attribute) => {
          return (
            <div
              key={attribute.id}
              className="m-2 rounded-full border-1 border-med-dark bg-primary-light p-4 text-center"
            >
              <p className="px-4 text-center font-body text-lg">
                {attribute.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PetAttributes;
