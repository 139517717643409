import { useQuery, useLazyQuery, gql } from "@apollo/client";
import * as MENUS from "constants/menus";
import { Layout, Blocks, Loading } from "features"; // Blocks eventually
import { NavigationMenu, PostCard, Tabs } from "components";
import { Button } from "components/Button";
import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  SEO_FRAGMENT,
  SEO_CONFIG_FRAGMENT,
  MEDIA_ITEM_FRAGMENT,
  FLEXIBLE_CONTENT_FRAGMENT,
} from "fragments";
import { useState } from "react";

export default function Component() {
  const { data, loading, error, fetchMore } = useQuery(Component.query, {
    variables: Component.variables(),
  });

  if (loading) {
    return <Loading type="page" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const {
    page,
    headerMenuItems,
    footerMenuItems,
    siteSettings,
    seo: defaultSEO,
    posts,
    categories,
  } = data;

  const { social } = defaultSEO;

  const {
    seo,
    title,
    flexibleContent: { blocks },
  } = page;
  const {
    address,
    customAddressLabel,
    phoneNumber,
    logo,
    logoWhite,
    logoAlt,
    cta,
    email,
    turnOnAnnouncements,
    announcements,
  } = siteSettings.siteSettings;
  const [pagePosts, setPagePosts] = useState(posts);

  const [loadMorePosts, { loading: loadingMorePosts }] = useLazyQuery(
    gql`
      query Posts($first: Int = 6, $after: String) {
        posts(first: $first, after: $after) {
          nodes {
            postOptions {
              isExternal
              externalLink
            }
            title
            slug
            date
            excerpt
            featuredImage {
              node {
                ...MediaItemFragment
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
      ${MEDIA_ITEM_FRAGMENT}
    `,
    {
      onCompleted: (data) => {
        setPagePosts({
          nodes: [...pagePosts.nodes, ...data.posts.nodes],
          pageInfo: data.posts.pageInfo,
        });
      },
    }
  );

  const hasMore = pagePosts.pageInfo?.hasNextPage;

  const tabs = [
    {
      name: "All News",
      slug: "all",
      content: pagePosts.nodes.map((post) => {
        return <PostCard key={post.id} post={post} />;
      }),
    },
    ...categories.nodes.map((category) => {
      return {
        name: category.name,
        slug: category.slug,
        content: pagePosts?.nodes.map((post) => {
          if (post.categories.nodes[0].slug === category.slug) {
            return <PostCard key={post.id} post={post} />;
          }
        }),
      };
    }),
  ];

  const handleLoadMore = () => {
    if (loadingMorePosts || !hasMore) {
      return;
    }
    loadMorePosts({
      variables: {
        first: 6,
        after: pagePosts.pageInfo.endCursor,
      },
    });
  };

  return (
    <Layout
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={seo}
      logo={logo}
      logoWhite={logoWhite}
      logoAlt={logoAlt}
      cta={cta}
      twitterUser={defaultSEO.social.twitter.username}
      address={address}
      customAddressLabel={customAddressLabel}
      phoneNumber={phoneNumber}
      email={email}
      social={social}
      turnOnAnnouncements={turnOnAnnouncements}
      announcements={announcements}
    >
      <Blocks blocks={blocks} />
      <div className="container relative mx-auto mb-12">
        <Tabs tabs={tabs} variant="primary" />
        {hasMore ? (
          <div className={`flex flex-col justify-center gap-4 pb-10`}>
            {loadingMorePosts ? (
              <div className="mt-12 flex items-center justify-center">
                <div
                  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            ) : null}
            <Button
              className={`mx-auto w-fit`}
              onClick={handleLoadMore}
              variant={`primary`}
              disabled={loadingMorePosts}
            >
              {loadingMorePosts ? "Loading..." : "Load More"}
            </Button>
          </div>
        ) : null}
      </div>
    </Layout>
  );
}

Component.query = gql`
  query NewsPage(
    $headerLocation: MenuLocationEnum!
    $footerLocation: MenuLocationEnum!
    $asPreview: Boolean = false
  ) {
    generalSettings {
      ...BlogInfoFragment
    }
    siteSettings {
      ...SiteSettingsFragment
    }
    seo {
      ...SEOConfigFragment
    }
    page(id: 129, idType: DATABASE_ID, asPreview: $asPreview) {
      id
      title
      seo {
        ...SEOFragment
      }
      flexibleContent {
        ...FlexibleContentFragment
      }
    }
    categories {
      nodes {
        id
        name
        slug
      }
    }
    posts(first: 6) {
      nodes {
        postOptions {
          isExternal
          externalLink
        }
        id
        title
        excerpt
        uri
        date
        categories {
          nodes {
            name
            slug
          }
        }
        featuredImage {
          node {
            ...MediaItemFragment
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${NavigationMenu.fragments.entry}
  ${SEO_FRAGMENT}
  ${SEO_CONFIG_FRAGMENT}
  ${MEDIA_ITEM_FRAGMENT}
  ${FLEXIBLE_CONTENT_FRAGMENT}
`;

Component.variables = (ctx) => {
  return {
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview ?? false,
  };
};
