import { Animal, Cat, RootQueryToAnimalConnectionEdge } from "graphql";
import { Button } from "../Button";
import Male from "public/icons/icon-male.svg";
import { BsGenderFemale } from "react-icons/bs";
import { FeaturedImage } from "components/FeaturedImage";
import getAgeString from "utilities/getAgeString";
import Link from "next/link";
import Image from "next/image";

export interface PetCardProps {
  pet?: Animal | Cat;
  variant: string;
  className?: string;
  showDetails?: boolean;
  i?: number;
  isFosterable?: boolean;
  onClick?: () => void;
}

function isAnimalFosterable(animalIntakeDateStr: string) {
  // Parse the intake date string into a Date object
  const animalIntakeDate = new Date(animalIntakeDateStr);

  // Get the current date
  const currentDate = new Date();

  // Calculate the date one year ago from the current date
  const aYearAgo = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 1)
  );

  // Check if the animal intake date is on or before the date a year ago
  return animalIntakeDate <= aYearAgo;
}

// function isAnimal(pet: Animal | Cat): pet is Animal {
//   return (pet as Animal).primaryBreeds !== undefined;
// }

// function isCat(pet: Animal | Cat): pet is Cat {
//   return (pet as Cat).breeds !== undefined;
// }

const PetCard = ({
  pet,
  variant,
  className,
  showDetails,
  i,
  isFosterable = true,
  onClick,
}: PetCardProps) => {
  if (!pet) return null;
  const isFeatured = variant === "featured";
  const isBasic = variant === "basic";
  const isCat = pet.__typename === "Cat";
  const isAnimal = pet.__typename === "Animal";

  const { animalDetails, uri } = pet || {};

  let breed, secondary;
  if (isAnimal) {
    const { primaryBreeds, secondaryBreeds } = pet;
    const { nodes: primaryBreed } = primaryBreeds || {};
    const { nodes: secondaryBreed } = secondaryBreeds || {};
    breed = primaryBreed && primaryBreed[0]?.name;
    secondary = secondaryBreed && secondaryBreed[0]?.name;
  } else if(isCat) {
    const { breeds } = pet;
    const { nodes: breedNodes } = breeds || {};
    breed = breedNodes && breedNodes[0]?.name;
  }

  const {
    animalWeight,
    animalBirthday,
    animalSex: sex,
    animalCoverPhoto,
  } = animalDetails || {};

  const isVirtualFosterable = isFosterable && isAnimalFosterable(
    animalDetails?.animalIntakeDate as string
  );

  const weight = animalWeight;
  // Get age from unix timestamp
  // convert animalBirthday from unix timestamp to age ex: 2 Years and 3 Months old
  const age = getAgeString(parseInt(animalBirthday as unknown as string));

  const isMale = sex === "Male";

  const trimmedContent = (content: any) => {
    if (content) {
      const strip = content.replace(/(<([^>]+)>)/gi, "");
      const trimmed = strip.substring(0, 200);
      return trimmed + "...";
    }
    return content;
  };

  const card = isFeatured
    ? `flex flex-col justify-center lg:flex-row w-full`
    : `relative bg-gray-50 shadow-lg shadow-stone-300 rounded-lg flex flex-col w-full p-2 h-auto ${
        isVirtualFosterable ? "lg:pb-[7rem]" : "lg:pb-14"
      }`;

  const cardImage = isFeatured
    ? `order-last w-full flex h-[300px] flex-col md:h-[400px] lg:order-first lg:h-[365px] lg:min-w-1/2`
    : `w-full rounded-lg overflow-hidden`;

  const cardContent = isFeatured
    ? `align-center order-last flex flex-col justify-center gap-6 pr-4 py-8 lg:min-w-1/2`
    : `w-full`;

  const petName = isFeatured
    ? `font-heading text-4xl leading-none text-dark`
    : `flex items-center font-heading text-3xl md:text-4xl leading-none text-dark my-4 flex justify-between border-b-2 border-gray-300 pb-2 w-full`;

  const petImage = isFeatured
    ? `w-full h-full object-contain`
    : `w-full h-auto object-cover`;

  return (
    <div className={card}>
      <div className={cardImage}>
        {animalCoverPhoto ? (
          <Link href={`${pet?.uri}`} className={cardImage} onClick={onClick} target="_blank">
            <div
              className={`flex w-full max-w-full items-center overflow-hidden`}
            >
              <Image
                src={animalCoverPhoto ?? ""}
                className={petImage}
                alt={pet?.animalDetails?.animalName ?? ""}
                width={365}
                height={365}
              />
            </div>
          </Link>
        ) : null}
      </div>
      <div className={cardContent}>
        <h2 className={petName}>
          <span className="flex-1">
            {isFeatured ? "Hi, I'm " : ""}
            {pet?.animalDetails?.animalName}
          </span>
          {isBasic && (
            <span
              className={`relative grid w-fit place-items-center text-center md:h-[40px]`}
            >
              {isMale ? (
                <Male className={`h-full w-fit`} />
              ) : (
                <>
                  <BsGenderFemale className={`h-full w-auto text-[#707070]`} />
                  <p className={`font-body text-xs text-[#707070]`}>Female</p>
                </>
              )}
            </span>
          )}
        </h2>
        {isFeatured ? (
          <div
            dangerouslySetInnerHTML={{
              __html: trimmedContent(
                pet?.animalDetails?.animalDescription || pet?.content
              ),
            }}
          />
        ) : null}
        {showDetails ? (
          <div
            className={`md:text-md flex flex-col gap-2 pb-4 font-body text-xs text-gray-500`}
          >
            <p>
              Breed: {breed} {secondary ? `/ ${secondary}` : ""}
            </p>
            {/* {weight ? <p>Weight: {weight} lbs.</p> : null} */}
            {age ? <p>Age: {age}</p> : null}
          </div>
        ) : null}
        <div
          className={`hidden lg:flex ${
            isBasic ? "absolute bottom-2 left-2 right-2" : ""
          } flex-col gap-4`}
        >
          <Button
            className={isFeatured ? "w-fit" : "w-full"}
            variant={isFeatured ? "secondary-outline" : "secondary"}
            href={uri as string}
            onClick={onClick}
            target={`_blank`}
          >
            {isBasic ? "Learn More" : "Adopt Me"}
          </Button>
          {isVirtualFosterable && (
            <Button
              className={`
                ${
                  isFeatured ? "w-fit text-center" : "w-full text-center"
                } lg:px-4
              `}
              variant={"secondary-outline"}
              href={`/virtual-foster?animalId=${pet?.animalDetails?.animalNid}&animalName=${pet?.animalDetails?.animalName}`}
              onClick={onClick}
              target={`_blank`}
            >
              Become Virtual Foster
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PetCard;
