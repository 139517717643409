import { ReactNode, useState } from "react";

export interface TabsProps {
  className?: string;
  tabs: [
    {
      name: string;
      content: ReactNode;
      slug: string;
    }
  ];
  activeTab?: number;
  variant?: "primary" | "secondary";
}

const Tabs = ({ className, tabs, activeTab, variant }: TabsProps) => {
  const [active, setActive] = useState(activeTab ? activeTab : 0);
  const isPrimary = variant === "primary";
  const isSecondary = variant === "secondary";
  const tabsLength = tabs.length;

  return (
    <div
      className={`${
        className ? className : ``
      } mx-auto flex max-w-4xl flex-col py-4 md:py-12`}
    >
      {/* Mobile Select Field */}
      <div className="relative px-4">
        <select
          className="focus:shadow-outline block w-full appearance-none rounded border border-gray-400 bg-white px-4 py-2 pr-8 leading-tight shadow hover:border-gray-500 focus:outline-none"
          value={tabs[active].slug}
          onChange={(e) =>
            setActive(tabs.findIndex((tab) => tab.slug === e.target.value))
          }
        >
          {tabs.map(({ name, slug }) => (
            <option key={`tab-mobile-${slug}`} value={slug}>
              {name}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center px-2 text-gray-700">
          <svg
            className="h-4 w-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M7 7l3-3 3 3v2H7V7zm0 6h6v2H7v-2z" />
          </svg>
        </div>
      </div>
      <div className={`flex flex-col`}>
        {tabs.map(({ content, slug }) => {
          if (tabs.findIndex((tab) => tab.slug === slug) === active) {
            return content;
          }
        })}
      </div>
    </div>
  );
};

export default Tabs;
