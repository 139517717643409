import dynamic from "next/dynamic";

const BasicGraphic = dynamic(
  () => import("public/hero-assets/paw-hero-asset.svg"),
  {
    ssr: true,
  }
);
const DogGroup1 = dynamic(() => import("public/hero-assets/dog-group-1.svg"), {
  ssr: true,
});
const DogGroup2 = dynamic(() => import("public/hero-assets/dog-group-2.svg"), {
  ssr: true,
});

const AfghanHound = dynamic(
  () => import("public/hero-assets/afghan-hound.svg"),
  {
    ssr: true,
  }
);

const Dalmatian = dynamic(() => import("public/hero-assets/dalmatian.svg"), {
  ssr: true,
});

const EnglishSheepdog = dynamic(
  () => import("public/hero-assets/english-sheepdog.svg"),
  {
    ssr: true,
  }
);

const GoldenPittie = dynamic(
  () => import("public/hero-assets/golden-pittie.svg"),
  {
    ssr: true,
  }
);

const HoundDash = dynamic(
  () => import("public/hero-assets/hound-dachshund.svg"),
  {
    ssr: true,
  }
);

const HoundFrenchie = dynamic(
  () => import("public/hero-assets/hound-frenchie.svg"),
  {
    ssr: true,
  }
);

const PittieTerrier = dynamic(
  () => import("public/hero-assets/pittie-terrier.svg"),
  {
    ssr: true,
  }
);

const Poodle = dynamic(() => import("public/hero-assets/poodle.svg"), {
  ssr: true,
});

const ShihTzuChi = dynamic(
  () => import("public/hero-assets/shih-tzu-chihuahua.svg"),
  {
    ssr: true,
  }
);

const VolunteerWalker = dynamic(
  () => import("public/hero-assets/volunteer-walker.svg"),
  {
    ssr: true,
  }
);
const VolunteerWalkerRight = dynamic(
  () => import("public/hero-assets/volunteer-walker-right.svg"),
  {
    ssr: true,
  }
);

const DonateShihTzu = dynamic(
  () => import("public/hero-assets/donate-shih-tzu.svg"),
  {
    ssr: true,
  }
);
const DonateAfghan = dynamic(
  () => import("public/hero-assets/donate-afghan.svg"),
  {
    ssr: true,
  }
);

export interface SlideGraphicProps {
  graphic?: string;
  className?: string;
}

const SideGraphic = ({ graphic, className }: SlideGraphicProps) => {
  switch (graphic) {
    case "goldenGroup": {
      // @ts-ignore
      return <DogGroup1 className={`${className ? className : ""} -left-16`} />;
    }
    case "terrierGroup": {
      // @ts-ignore
      return <DogGroup2 className={`${className ? className : ""}`} />;
    }
    case "afghanHound": {
      // @ts-ignore
      return <AfghanHound className={`${className ? className : ""}`} />;
    }
    case "dalmatian": {
      // @ts-ignore
      return <Dalmatian className={`${className ? className : ""}`} />;
    }
    case "englishSheepdog": {
      // @ts-ignore
      return <EnglishSheepdog className={`${className ? className : ""}`} />;
    }
    case "goldenPittie": {
      // @ts-ignore
      return <GoldenPittie className={`${className ? className : ""}`} />;
    }
    case "houndDash": {
      // @ts-ignore
      return <HoundDash className={`${className ? className : ""}`} />;
    }
    case "houndFrenchie": {
      // @ts-ignore
      return <HoundFrenchie className={`${className ? className : ""}`} />;
    }
    case "pittieTerrier": {
      // @ts-ignore
      return <PittieTerrier className={`${className ? className : ""}`} />;
    }
    case "poodle": {
      // @ts-ignore
      return <Poodle className={`${className ? className : ""}`} />;
    }
    case "shihTzuChi": {
      return (
        <ShihTzuChi
          // @ts-ignore
          className={`${className ? className : ""} bottom-[-10px]`}
        />
      );
    }
    case "volunteerWalker": {
      return (
        <VolunteerWalker
          // @ts-ignore
          className={`${className ? className : ""} absolute bottom-0 h-[140%]`}
        />
      );
    }
    case "volunteerWalkerRight": {
      return (
        <VolunteerWalkerRight
          // @ts-ignore
          className={`${className ? className : ""} absolute bottom-0 h-[140%]`}
        />
      );
    }
    case "donateShihTzu": {
      return (
        <DonateShihTzu
          // @ts-ignore
          className={`${className ? className : ""} bottom-[-11px]`}
        />
      );
    }
    case "donateAfghan": {
      return (
        <DonateAfghan
          // @ts-ignore
          className={`${className ? className : ""} bottom-[-9px]`}
        />
      );
    }
    default: {
      // @ts-ignore - this is a dynamic import
      return <BasicGraphic className={`${className ? className : ""}`} />;
    }
  }
};

export default SideGraphic;
