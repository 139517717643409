import React, { useEffect, useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { start } from "repl";

interface FormspreeProps {
  id: string;
}

const Formspree = ({ id }: FormspreeProps) => {
  const [state, handleSubmit] = useForm(id);
  const [seconds, setSeconds] = useState(5);
  const [price, setPrice] = useState(200);
  const [startTimer, setStartTimer] = useState(false);

  const handlePriceChange = (e: any) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const inputPrice = selectedOption.dataset.price;
    setPrice(parseInt(inputPrice));
  };

  useEffect(() => {
    if (startTimer) {
      const countdownInterval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
      return () => clearInterval(countdownInterval);
    }
  }, [startTimer]);

  useEffect(() => {
    if (seconds === 1) {
      window.location.href = `/api/stripe/donation/?price=${price}&title=${encodeURIComponent(
        "Transport Reservation Form"
      )}`;
    }
  }, [seconds]);

  if (state.succeeded) {
    if (!startTimer) {
      setStartTimer(true);
    }
    return (
      <div>
        <h2 className={`mb-2 text-2xl font-bold`}>
          Thanks for your submission!
        </h2>
        <p>You will be redirected to the payment page in {seconds} seconds.</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mx-auto flex max-w-xl flex-col gap-4">
        <fieldset className="flex w-full flex-col justify-center gap-4 md:flex-row">
          <legend className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800">
            Name<sup className="text-secondary">*</sup>
          </legend>
          <div className="w-full">
            <label className={`hidden`} htmlFor="firstName">
              First
            </label>
            <input
              type="text"
              className="w-full rounded-lg px-4 py-2 font-body"
              name="firstName"
              id="firstName"
              placeholder="First*"
              autoComplete="given-name"
            />
            <ValidationError
              prefix="First name"
              field="first"
              errors={state.errors}
            />
          </div>
          <div className="w-full">
            <label className={`hidden`} htmlFor="lastName">
              Last
            </label>
            <input
              type="text"
              className="w-full rounded-lg px-4 py-2 font-body"
              name="lastName"
              id="lastName"
              placeholder="Last*"
              autoComplete="family-name"
            />
            <ValidationError
              prefix="Last name"
              field="last"
              errors={state.errors}
            />
          </div>
        </fieldset>
        <p className="text-left font-body text-sm text-gray-500">
          Rescue Coordinator (Individual filling in this form) and Name of
          Rescue (if applicable)
        </p>
        <div className="">
          <label
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
            htmlFor="date"
          >
            Date<sup className="text-secondary">*</sup>
          </label>
          <input
            type="date"
            name="date"
            id="date"
            className="w-full rounded-lg px-4 py-2 font-body text-gray-400"
            required
            placeholder="Date*"
          />
          <ValidationError prefix="Date" field="date" errors={state.errors} />
        </div>
        <fieldset id="consent" className="">
          <legend className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800">
            Consent<sup className="text-secondary">*</sup>
          </legend>
          <div className="flex gap-4">
            <input
              type="checkbox"
              name="consentAgreement"
              id="consentAgreement"
              value="I Understand and Agree"
            />
            <label className="text-gray-800" htmlFor="consentAgreement">
              I Understand and Agree
            </label>
            <ValidationError
              prefix="Consent"
              field="consentAgreement"
              errors={state.errors}
            />
          </div>
          <p className="text-left font-body text-sm text-gray-500">
            I have read the page at Transportation Requirements. I understand
            that transport can be refused if the animal(s) I am shipping do not
            comply with the stated requirements. Signatures will be required at
            pick-up and delivery locations. Picture ID required at pick-up.
          </p>
        </fieldset>
        <fieldset
          id="dropOffContact"
          className="flex w-full flex-col justify-center gap-4 md:flex-row"
        >
          <legend className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800">
            Contact Person Responsible for Drop-Off
            <sup className="text-secondary">*</sup>
          </legend>
          <div className="w-full">
            <label className={`hidden`} htmlFor="dropOffContactFirstName">
              First
            </label>
            <input
              type="text"
              className="w-full rounded-lg px-4 py-2 font-body"
              name="dropOffContactFirstName"
              id="dropOffContactFirstName"
              placeholder="First*"
              autoComplete="given-name"
            />
            <ValidationError
              prefix="First name"
              field="dropOffContactFirstName"
              errors={state.errors}
            />
          </div>
          <div className="w-full">
            <label className={`hidden`} htmlFor="dropOffContactLastName">
              Last
            </label>
            <input
              type="text"
              className="w-full rounded-lg px-4 py-2 font-body"
              name="dropOffContactLastName"
              id="dropOffContactLastName"
              placeholder="Last*"
              autoComplete="family-name"
            />
            <ValidationError
              prefix="Last name"
              field="dropOffContactLastName"
              errors={state.errors}
            />
          </div>
        </fieldset>
        <p className="text-left font-body text-sm text-gray-500">
          Beginning / Departure
        </p>
        <fieldset id="dropOffContactAddress" className="flex flex-col gap-4">
          <legend className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800">
            Address<sup className="text-secondary">*</sup>
          </legend>
          <div>
            <label className="hidden" htmlFor="dropOffContactAddressLine1">
              Street Address
            </label>
            <input
              type="text"
              name="dropOffContactAddressLine1"
              id="dropOffContactAddressLine1"
              className="w-full rounded-lg px-4 py-2 font-body text-gray-700"
              placeholder="Street Address"
              autoComplete="street-address"
            />
            <ValidationError
              prefix="Street address"
              field="dropOffContactAddressLine1"
              errors={state.errors}
            />
          </div>
          <div>
            <label className="hidden" htmlFor="addressLine2">
              Address Line 2
            </label>
            <input
              type="text"
              name="dropOffContactAddressLine2"
              id="dropOffContactAddressLine2"
              className="w-full rounded-lg px-4 py-2 font-body text-gray-700"
              placeholder="Address Line 2"
              autoComplete="address-line2"
            />
            <ValidationError
              prefix="Address line 2"
              field="dropOffContactAddressLine2"
              errors={state.errors}
            />
          </div>
          <div>
            <label className="hidden" htmlFor="addressCity">
              City
            </label>
            <input
              type="text"
              name="dropOffContactAddressCity"
              id="dropOffContactAddressCity"
              className="w-full rounded-lg px-4 py-2 font-body text-gray-700"
              placeholder="City"
              autoComplete="address-city"
            />
            <ValidationError
              prefix="City"
              field="dropOffContactAddressCity"
              errors={state.errors}
            />
          </div>
          <div>
            <label className="hidden" htmlFor="addressState">
              State / Province
            </label>
            <input
              type="text"
              name="dropOffContactAddressState"
              id="dropOffContactAddressState"
              className="w-full rounded-lg px-4 py-2 font-body text-gray-700"
              placeholder="State / Province"
              autoComplete="address-state"
            />
            <ValidationError
              prefix="State / Province"
              field="dropOffContactAddressState"
              errors={state.errors}
            />
          </div>
          <div>
            <label className="hidden" htmlFor="addressZip">
              ZIP / Postal Code
            </label>
            <input
              type="text"
              name="dropOffContactAddressZip"
              id="dropOffContactAddressZip"
              placeholder="ZIP / Postal Code"
              className="w-full rounded-lg px-4 py-2 font-body text-gray-700"
              autoComplete="address-zip"
            />
            <ValidationError
              prefix="ZIP / Postal code"
              field="dropOffContactAddressZip"
              errors={state.errors}
            />
          </div>
        </fieldset>
        <div className="">
          <label
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
            htmlFor="dropOffContactPhone"
          >
            Phone<sup className="text-secondary">*</sup>
          </label>
          <input
            type="tel"
            name="dropOffContactPhone"
            className="w-full rounded-lg px-4 py-2 font-body text-gray-700 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-300"
            id="dropOffContactPhone"
            required
            placeholder="Phone*"
            autoComplete="tel"
          />
          <ValidationError
            prefix="Phone"
            field="dropOffContactPhone"
            errors={state.errors}
          />
        </div>
        <div className="">
          <label
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
            htmlFor="dropOffContactEmail"
          >
            Email<sup className="text-secondary">*</sup>
          </label>
          <input
            type="email"
            name="dropOffContactEmail"
            className="w-full rounded-lg px-4 py-2 font-body"
            id="dropOffContactEmail"
            placeholder="Email*"
            required
          />
          <ValidationError
            prefix="Email"
            field="dropOffContactEmail"
            errors={state.errors}
          />
        </div>
        <fieldset
          id="pickUpContact"
          className="flex w-full flex-col justify-center gap-4 md:flex-row"
        >
          <legend className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800">
            Contact Person Responsible for Pick-Up*
            <sup className="text-secondary">*</sup>
          </legend>
          <div className="w-full">
            <label htmlFor="pickUpContactFirstName">First</label>
            <input
              type="text"
              className="w-full rounded-lg px-4 py-2 font-body"
              name="pickUpContactFirstName"
              id="pickUpContactFirstName"
              placeholder="First*"
            />
            <ValidationError
              prefix="First name"
              field="pickUpContactFirstName"
              errors={state.errors}
            />
          </div>
          <div className="w-full">
            <label htmlFor="pickUpContactLastName">Last</label>
            <input
              type="text"
              className="w-full rounded-lg px-4 py-2 font-body"
              name="pickUpContactLastName"
              id="pickUpContactLastName"
              placeholder="Last*"
            />
            <ValidationError
              prefix="Last name"
              field="pickUpContactLastName"
              errors={state.errors}
            />
          </div>
        </fieldset>
        <p className="text-left font-body text-sm text-gray-500">
          Destination / Arrival
        </p>
        <fieldset id="pickUpContactAddress" className="flex flex-col gap-4">
          <legend className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800">
            Address<sup className="text-secondary">*</sup>
          </legend>
          <div>
            <label className="hidden" htmlFor="pickUpContactAddressLine1">
              Street Address
            </label>
            <input
              type="text"
              name="pickUpContactAddressLine1"
              id="pickUpContactAddressLine1"
              className="w-full rounded-lg px-4 py-2 font-body text-gray-700"
              placeholder="Street Address"
            />
            <ValidationError
              prefix="Street address"
              field="pickUpContactAddressLine1"
              errors={state.errors}
            />
          </div>
          <div>
            <label className="hidden" htmlFor="pickUpContactAddressLine2">
              Address Line 2
            </label>
            <input
              type="text"
              name="pickUpContactAddressLine2"
              id="pickUpContactAddressLine2"
              className="w-full rounded-lg px-4 py-2 font-body text-gray-700"
              placeholder="Address Line 2"
            />
            <ValidationError
              prefix="Address line 2"
              field="pickUpContactAddressLine2"
              errors={state.errors}
            />
          </div>
          <div>
            <label className="hidden" htmlFor="pickUpContactAddressCity">
              City
            </label>
            <input
              type="text"
              name="pickUpContactAddressCity"
              id="pickUpContactAddressCity"
              className="w-full rounded-lg px-4 py-2 font-body text-gray-700"
              placeholder="City"
            />
            <ValidationError
              prefix="City"
              field="pickUpContactAddressCity"
              errors={state.errors}
            />
          </div>
          <div>
            <label className="hidden" htmlFor="pickUpContactAddressState">
              State / Province
            </label>
            <input
              type="text"
              name="pickUpContactAddressState"
              id="pickUpContactAddressState"
              className="w-full rounded-lg px-4 py-2 font-body text-gray-700"
              placeholder="State / Province"
            />
            <ValidationError
              prefix="State / Province"
              field="pickUpContactAddressState"
              errors={state.errors}
            />
          </div>
          <div>
            <label className="hidden" htmlFor="pickUpContactAddressZip">
              ZIP / Postal Code
            </label>
            <input
              type="text"
              name="pickUpContactAddressZip"
              id="pickUpContactAddressZip"
              className="w-full rounded-lg px-4 py-2 font-body text-gray-700"
              placeholder="ZIP / Postal Code"
            />
            <ValidationError
              prefix="ZIP / Postal code"
              field="pickUpContactAddressZip"
              errors={state.errors}
            />
          </div>
        </fieldset>
        <div className="">
          <label
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
            htmlFor="pickUpContactPhone"
          >
            Phone<sup className="text-secondary">*</sup>
          </label>
          <input
            type="tel"
            name="pickUpContactPhone"
            className="w-full rounded-lg px-4 py-2 font-body text-gray-700 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-300"
            id="pickUpContactPhone"
            required
          />
          <ValidationError
            prefix="Phone"
            field="pickUpContactPhone"
            errors={state.errors}
          />
        </div>
        <div className="gfield gfield-EMAIL">
          <label
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
            htmlFor="pickUpContactEmail"
          >
            Email<sup className="text-secondary">*</sup>
          </label>
          <input
            type="email"
            name="pickUpContactEmail"
            className="w-full rounded-lg px-4 py-2 font-body"
            id="pickUpContactEmail"
            placeholder="Email*"
            required
          />
          <ValidationError
            prefix="Email"
            field="pickUpContactEmail"
            errors={state.errors}
          />
        </div>
        <div className="">
          <div className="text-md block px-2 py-8 text-left font-body leading-5 text-gray-800">
            Transport Route Stops Please note* Pets can travel as ‘Singles’ or
            with ‘Partners’. Choose from drop down list – *Crate Accommodations.
          </div>
        </div>
        <div className="price flex flex-col gap-2">
          <label
            htmlFor="crateAccommodations"
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
          >
            Crate Accommodations<sup className="text-secondary">*</sup>
          </label>
          <select
            className="ml-xs my-sm rounded-lg px-4 py-2"
            name="crateAccommodations"
            id="crateAccommodations"
            onChange={handlePriceChange}
            required
          >
            <option data-price={200} value="1 Dog (Single) Small Crate">
              1 Dog (Single) Small Crate
            </option>
            <option data-price={200} value="1 Dog (Single) Med Crate">
              1 Dog (Single) Med Crate
            </option>
            <option data-price={200} value="1 Dog (Single) Lg Crate">
              1 Dog (Single) Lg Crate
            </option>
            <option data-price={300} value="2 Dogs (Partner) Lg Crate">
              2 Dogs (Partner) Lg Crate
            </option>
            <option data-price={300} value="2 Puppies (Partner) Med Crate">
              2 Puppies (Partner) Med Crate
            </option>
            <option data-price={300} value="3 Puppies (Partner) Lg Crate">
              3 Puppies (Partner) Lg Crate
            </option>
          </select>
          <ValidationError
            prefix="Crate Accommodations"
            field="crateAccommodations"
            errors={state.errors}
          />
        </div>
        <div className="">
          <label
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
            htmlFor="numberOfMales"
          >
            Number of Males
          </label>
          <input
            type="number"
            pattern="[0-9]*"
            name="numberOfMales"
            id="numberOfMales"
            className="w-full rounded-lg px-4 py-2 font-body text-gray-700 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-300"
            placeholder="Number of Males"
          />
          <ValidationError
            prefix="Number of Males"
            field="numberOfMales"
            errors={state.errors}
          />
        </div>
        <div className="">
          <label
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
            htmlFor="numberOfFemales"
          >
            Number of Females
          </label>
          <input
            type="number"
            pattern="[0-9]*"
            name="numberOfFemales"
            id="numberOfFemales"
            className="w-full rounded-lg px-4 py-2 font-body text-gray-700 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-300"
            placeholder="Number of Females"
          />
          <ValidationError
            prefix="Number of Females"
            field="numberOfFemales"
            errors={state.errors}
          />
        </div>
        <div className="">
          <label
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
            htmlFor="petDescription"
          >
            Description of each pet:<sup className="text-secondary">*</sup>
          </label>
          <textarea
            name="petDescription"
            id="petDescription"
            required
            className="min-h-xs w-full rounded-lg px-4 py-2 font-body text-gray-500"
            placeholder="Description of each pet:*"
          ></textarea>
          <ValidationError
            prefix="Description of each pet"
            field="petDescription"
            errors={state.errors}
          />
          <p className="text-left font-body text-sm text-gray-500">
            Include Name(s), Breed, Color, Age &amp; Weight - Example: 2 unnamed
            Beagle Mix pups, brown &amp; white, approx. 6 mo. old, about 13 lbs.
            each
          </p>
        </div>
        <div className="">
          <label
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
            htmlFor="additionalInformation"
          >
            Additional Information
          </label>
          <textarea
            name="additionalInformation"
            id="additionalInformation"
            className="min-h-xs w-full rounded-lg px-4 py-2 font-body text-gray-500"
            placeholder="Additional Information"
          ></textarea>
          <ValidationError
            prefix="Additional Information"
            field="additionalInformation"
            errors={state.errors}
          />
          <p className="text-left font-body text-sm text-gray-500">
            Anything important for animal handlers to know.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="dropOffLocation"
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
          >
            Select your Drop-Off location<sup className="text-secondary">*</sup>
          </label>
          <select
            className="ml-xs my-sm rounded-lg px-4 py-2"
            name="dropOffLocation"
            id="dropOffLocation"
            required
          >
            <option value="San Antonio, TX">San Antonio, TX</option>
            <option value="Von Ormy, TX">Von Ormy, TX</option>
            <option value="Katy, TX">Katy, TX</option>
            <option value="Dallas, TX">Dallas, TX</option>
            <option value="Other">Other</option>
          </select>
          <ValidationError
            prefix="Drop-Off location"
            field="dropOffLocation"
            errors={state.errors}
          />
          <p className="text-left font-body text-sm text-gray-500">
            Pickup locations not listed above please send request to
            transport@godsdogsrescue.org
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="pickUpLocation"
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
          >
            Select your Pick-Up location (Pacific Northwest)
            <sup className="text-secondary">*</sup>
          </label>
          <select
            className="ml-xs my-sm rounded-lg px-4 py-2"
            name="pickUpLocationPacificNorthwest"
            id="pickUpLocationPacificNorthwest"
            required
          >
            <option value="Olympia Washington">Olympia Washington</option>
            <option value="Bothell Washington">Bothell Washington</option>
            <option value="Federal Way Washington">
              Federal Way Washington
            </option>
            <option value="Portland Oregon">Portland Oregon</option>
            <option value="Other">Other</option>
            <option value="None">None</option>
          </select>
          <ValidationError
            prefix="Pick-Up location (Pacific Northwest)"
            field="pickUpLocationPacificNorthwest"
            errors={state.errors}
          />
          <p className="text-left font-body text-sm text-gray-500">
            Pet&apos;s destination (Pacific Northwest). Pickup locations not
            listed above please send request to transport@godsdogsrescue.org
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="pickUpLocationMidwest"
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
          >
            Select your Pick-Up location (MidWest)
            <sup className="text-secondary">*</sup>
          </label>
          <select
            className="ml-xs my-sm rounded-lg px-4 py-2"
            name="pickUpLocationMidwest"
            id="pickUpLocationMidwest"
            required
          >
            <option value="St Louis, Missouri">St Louis, Missouri</option>
            <option value="Chicago, Illinois">Chicago, Illinois</option>
            <option value="Milwaukee, Wisconsin">Milwaukee, Wisconsin</option>
            <option value="Minneapolis, Minnesota">
              Minneapolis, Minnesota
            </option>
            <option value="Des Moines, Iowa">Des Moines, Iowa</option>
            <option value="Omaha, Nebraska">Omaha, Nebraska</option>
            <option value="Other">Other</option>
            <option value="None">None</option>
          </select>
          <ValidationError
            prefix="Pick-Up location (MidWest)"
            field="pickUpLocationMidwest"
            errors={state.errors}
          />
          <p className="text-left font-body text-sm text-gray-500">
            Pet&apos;s destination (MidWest). Pickup locations not listed above
            please send request to transport@godsdogsrescue.org
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="pickUpLocationNortheast"
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
          >
            Select your Pick-Up location (Northeast / New England)
            <sup className="text-secondary">*</sup>
          </label>
          <select
            className="ml-xs my-sm rounded-lg px-4 py-2"
            name="pickUpLocationNortheast"
            id="pickUpLocationNortheast"
            required
          >
            <option value="Cincinnati, Ohio">Cincinnati, Ohio</option>
            <option value="Pittsburgh, Pennsylvania">
              Pittsburgh, Pennsylvania
            </option>
            <option value="Buffalo, New York">Buffalo, New York</option>
            <option value="Albany, New York">Albany, New York</option>
            <option value="Brattleboro, Vermont">Brattleboro, Vermont</option>
            <option value="Hartford, Connecticut">Hartford, Connecticut</option>
            <option value="Allentown, Pennsylvania">
              Allentown, Pennsylvania
            </option>
            <option value="Philadelphia, Pennsylvania">
              Philadelphia, Pennsylvania
            </option>
            <option value="Other">Other</option>
            <option value="None">None</option>
          </select>
          <ValidationError
            prefix="Pick-Up location (Northeast / New England)"
            field="pickUpLocationNortheast"
            errors={state.errors}
          />
          <p className="text-left font-body text-sm text-gray-500">
            Pet&apos;s destination (Northeast / New England). Pickup locations
            not listed above please send request to transport@godsdogsrescue.org
          </p>
        </div>
        <fieldset
          id="fileUploadFieldset"
          className="flex w-full flex-col justify-center gap-4 md:flex-row"
        >
          <label
            className="text-body mb-2 block text-left font-body text-lg leading-5 text-gray-800"
            htmlFor="fileUpload"
          >
            File
          </label>
          <input type="file" name="fileUpload" id="fileUpload" />
          <ValidationError
            prefix="File"
            field="fileUpload"
            errors={state.errors}
          />
          <p className="text-left font-body text-sm text-gray-500">
            Image files (.jpg, .jpeg, .webp, .avif) up to 25MB (max size)
          </p>
        </fieldset>
        <div className="">
          <div className="text-md block px-2 py-8 text-left font-body leading-5 text-gray-800">
            Reservation is NOT guaranteed until payment is made.
          </div>
        </div>
        <button
          className="mx-auto min-w-[165px] max-w-fit rounded-full border-2 border-secondary bg-secondary px-8 py-2 text-center font-body uppercase text-white transition duration-300 ease-in-out hover:bg-white hover:text-secondary hover:shadow-lg"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default Formspree;
