import { Button } from "components/Button";
import Image from "next/image";
import { Page_Flexiblecontent_Blocks_TextBlock } from "graphql";

export interface TextBlockProps extends Page_Flexiblecontent_Blocks_TextBlock {
  className?: string;
}

const TextBlock = ({ className, paragraphs }: TextBlockProps) => {
  const hasParagraphs = paragraphs && paragraphs.length > 0;

  return (
    hasParagraphs &&
    paragraphs.map((paragraph, index) => {
      const {
        content,
        title,
        hasCtas,
        backgroundColor,
        contentAlignment,
        hasHeadingIcon,
        headingIcon,
        ctaRepeater,
        sectionId,
      } = paragraph || {};

      return (
        <div
          id={sectionId as string}
          className={`${
            className ? className : ``
          } relative bg-${backgroundColor} px-4 py-8 md:py-16`}
          key={`text-block-${index}`}
        >
          <div
            className={`mx-auto flex w-full max-w-[1320px] flex-col gap-6 text-${contentAlignment}`}
          >
            {hasHeadingIcon && headingIcon ? (
              <div className={`flex justify-${contentAlignment}`}>
                <Image src={headingIcon?.sourceUrl ?? ""} width={100} height={100} alt={headingIcon?.altText ?? ""} className="w-24 mb-4" />
              </div>
            ) : null}
            {title ? (
              <h2 className={`font-heading text-5xl text-dark`}>{title}</h2>
            ) : null}
            {content ? (
              <div
                className={`text-${contentAlignment} mx-auto max-w-[950px] font-heading text-2xl leading-[2.5rem] text-dark`}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            ) : null}
            {hasCtas && ctaRepeater ? (
              <div className={`flex flex-col gap-4 items-${contentAlignment}`}>
                {ctaRepeater.map((cta, index) => {
                  const { ctaLink, type } = cta || {};
                  const { url, title } = ctaLink || {};
                  return (
                    <Button
                      key={`cta-${index}`}
                      className={`w-fit`}
                      variant={type ?? `primary`}
                      href={url ?? ``}
                    >
                      {title}
                    </Button>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      );
    })
  );
};

export default TextBlock;
