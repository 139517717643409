import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import Filter from "./Filter";
import FilterAccordion from "./FilterAccordion";
import MobileFilters from "./MobileFilters";

interface FilterListProps {
  total: number;
  selectedFilters: any;
  setSelectedFilters: any;
  hasSelectedFilters: boolean;
  isMobile: boolean;
  isMobileFilterOpen: boolean;
  setIsMobileFilterOpen: any;
  handleSearch: any;
}

const GET_TAXONOMIES = gql`
  query GetTaxonomies($first: Int!) {
    primaryBreeds(first: $first) {
      nodes {
        id
        name
        slug
        termTaxonomyId
      }
    }
    weightGroups(first: $first) {
      nodes {
        id
        name
        slug
        termTaxonomyId
      }
    }
    ageGroups(first: $first) {
      nodes {
        id
        name
        slug
        termTaxonomyId
      }
    }
    petAttributes(first: $first) {
      nodes {
        id
        name
        slug
        termTaxonomyId
      }
    }
    genders(first: $first) {
      nodes {
        id
        name
        slug
        termTaxonomyId
      }
    }
    virtualFosterables(first: $first) {
      nodes {
        id
        name
        slug
        termTaxonomyId
      }
    }
  }
`;

const FilterList = ({
  total,
  hasSelectedFilters,
  selectedFilters,
  setSelectedFilters,
  isMobile,
  setIsMobileFilterOpen,
  handleSearch,
}: FilterListProps) => {
  const [isItemExpanded, setIsItemExpanded] = useState(false);
  const { loading, error, data } = useQuery(GET_TAXONOMIES, {
    variables: {
      first: 100,
    },
  });

  const filters = [
    {
      name: "Primary Breed",
      filters: data?.primaryBreeds.nodes || [],
      filterSlug: "primaryBreed",
    },
    {
      name: "Sex",
      filters: data?.genders.nodes || [],
      filterSlug: "gender",
    },
    {
      name: "Age",
      filters: data?.ageGroups.nodes || [],
      filterSlug: "ageGroup",
    },
    {
      name: "Size Groups",
      filters: data?.weightGroups.nodes || [],
      filterSlug: "weightGroup",
    },
    {
      name: "Attributes",
      filters: data?.petAttributes.nodes || [],
      filterSlug: "petAttribute",
    },
    {
      name: "Virtual Fosters",
      filters: data?.virtualFosterables.nodes || [],
      filterSlug: "virtualFosterable",
    },
  ];

  const handleSelectFilter = (e: any) => {
    const { value, checked } = e.target;
    const category = e.target.dataset.filter;

    if (checked) {
      setSelectedFilters((prev: any) => ({
        ...prev,
        [category]: [...prev[category], value],
      }));
    } else {
      setSelectedFilters((prev: any) => ({
        ...prev,
        [category]: prev[category].filter((item: any) => item !== value),
      }));
    }
  };

  const handleClearFilters = () => {
    setSelectedFilters({
      primaryBreed: [],
      gender: [],
      weightGroup: [],
      ageGroup: [],
      petAttribute: [],
      virtualFosterable: [],
    });
  };

  const handleCloseFilter = () => {
    setIsMobileFilterOpen(false);
  };

  const handleCloseItem = () => {
    setIsItemExpanded(!isItemExpanded);
  };

  const filterClass = `lg:left-0 lg:top-52 lg:flex lg:max-h-full lg:w-full lg:flex-row lg:justify-between lg:py-4 md:pr-2 lg:flex-col lg:pl-0 lg:pr-6`;

  if (error)
    return (
      <div className={filterClass}>
        <p>An error has occured. Please reload the page and try again.</p>
        <p>{error.message}</p>
      </div>
    );

  return (
    <>
      {!loading && (
        <>
          <div
            className={`relative flex h-auto w-full flex-row items-center justify-end border-b-2 border-b-slate-500 bg-white py-3 pr-5 lg:hidden`}
          >
            {isItemExpanded && (
              <button
                className={`absolute left-4 top-1/2 flex h-[44px] w-[44px] -translate-y-1/2 flex-row items-center justify-center gap-1 rounded-md bg-primary p-3 font-bold`}
                onClick={handleCloseItem}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-4 w-4`}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#FFF"
                    d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
                  />
                </svg>
              </button>
            )}
            <h3 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-body text-xl text-dark">
              {total} Total Dogs
            </h3>
            <button
              className={`flex flex-row items-center gap-1 rounded-md bg-primary p-3 font-bold`}
              onClick={handleCloseFilter}
            >
              <svg
                className={`h-5 w-5`}
                clipRule="evenodd"
                fillRule="evenodd"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#FFF"
                  d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                />
              </svg>
            </button>
          </div>
          <div className={filterClass}>
            <div className="hidden w-full items-center justify-between lg:flex">
              {total ? (
                <h3 className="hidden font-body text-xl text-dark lg:block">
                  {total} Total Dogs
                </h3>
              ) : null}
              {hasSelectedFilters && (
                <div className="">
                  <button
                    className="hidden font-body text-sm text-dark lg:block"
                    onClick={handleClearFilters}
                  >
                    Clear Filters
                  </button>
                </div>
              )}
            </div>
            {isMobile && (
              <div className={`relative w-full p-3 md:hidden`}>
                <input
                  type="text"
                  placeholder="Search"
                  className={`mx-0 h-auto w-full rounded-md bg-[#F4F4F4] px-3 py-3 md:px-6 md:py-3 lg:rounded-md`}
                  onChange={handleSearch}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  className={`opacity-42 pointer-events-none absolute right-5 top-1/2 -translate-y-1/2 transform text-slate-500`}
                >
                  <path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" />
                </svg>
              </div>
            )}
            <form className="flex flex-col lg:mt-6">
              <ul className="flex w-full flex-col border-gray-300 font-body lg:border-t">
                {filters.map((filter, i) =>
                  isMobile ? (
                    <MobileFilters
                      key={`filter-${i}`}
                      filter={filter}
                      i={i}
                      open={!isMobile && i === 0 ? true : false}
                      isMobile={isMobile}
                      selectedFilters={selectedFilters}
                      isItemExpanded={isItemExpanded}
                      setIsItemExpanded={setIsItemExpanded}
                    >
                      <Filter
                        category={filter.filterSlug as string}
                        filters={filter.filters}
                        selectedFilters={selectedFilters}
                        hasSelectedFilters={hasSelectedFilters}
                        onChange={handleSelectFilter}
                      />
                    </MobileFilters>
                  ) : (
                    <FilterAccordion
                      key={`filter-${i}`}
                      title={filter.name}
                      i={i}
                      open={!isMobile && i === 0 ? true : false}
                    >
                      <Filter
                        category={filter.filterSlug as string}
                        filters={filter.filters}
                        selectedFilters={selectedFilters}
                        hasSelectedFilters={hasSelectedFilters}
                        onChange={handleSelectFilter}
                      />
                    </FilterAccordion>
                  )
                )}
              </ul>
            </form>
          </div>
          <div
            className={`absolute bottom-0 left-0 flex w-full flex-row text-center lg:hidden`}
          >
            <button
              className={`flex-1 bg-secondary p-4 font-semibold tracking-[0.035em] text-white`}
              onClick={handleClearFilters}
            >
              RESET
            </button>
            <button
              className={`flex-1 bg-primary p-4 font-semibold tracking-[0.035em] text-white`}
              onClick={handleCloseFilter}
            >
              APPLY
            </button>
          </div>
        </>
      )}
      {loading && (
        <div className="left-0 top-52 flex max-h-full w-full animate-pulse flex-row justify-between py-4 md:sticky md:pr-2 lg:flex-col lg:pl-0 lg:pr-6">
          <div className={`my-3 h-14 rounded-md bg-gray-300`} />
          <div className={`mb-2 mt-4 h-14 rounded-md bg-gray-300`} />
          <div className={`my-1 h-5 rounded-md bg-gray-300`} />
          <div className={`my-1 h-5 rounded-md bg-gray-300`} />
          <div className={`my-1 h-5 rounded-md bg-gray-300`} />
          <div className={`my-1 h-5 rounded-md bg-gray-300`} />
          <div className={`my-1 h-5 rounded-md bg-gray-300`} />
          <div className={`mb-3 mt-1 h-5 rounded-md bg-gray-300`} />
          <div className={`my-2 h-14 rounded-md bg-gray-300`} />
          <div className={`my-2 h-14 rounded-md bg-gray-300`} />
          <div className={`my-2 h-14 rounded-md bg-gray-300`} />
        </div>
      )}
    </>
  );
};

export default FilterList;
