import { gql } from "@apollo/client";
import { MEDIA_ITEM_FRAGMENT } from "./MediaItemFragment";
import { SEO_FRAGMENT } from "./SEOFragment";
import { EVENT_FLEXIBLE_CONTENT_FRAGMENT } from "./EventFlexibleContentFragment";
import { EVENT_FEED_FRAGMENT } from "./EventFeedFragment";

export const SINGLE_EVENT_FRAGMENT = gql`
  fragment SingleEventFragment on Event {
    id
    title
    seo {
      ...SEOFragment
    }
    featuredImage {
      node {
        ...MediaItemFragment
      }
    }
    sidebarOptions {
      sidebarOptions {
        dogsWidget
        useSidebar
        donateWidget
      }
    }
    flexibleContent {
      ...EventFlexibleContentFragment
    }
    relatedEvents {
      nodes {
        ...EventFeedFragment
      }
    }
    eventOptions {
      multipleDays
      featured
      dateRange {
        date
      }
      multiDays {
        date
        startTime
        endTime
      }
      startTime
      endTime
      link {
        title
        target
        url
      }
      date
      customAddressLabel
      address {
        city
        country
        countryShort
        latitude
        longitude
        placeId
        postCode
        state
        stateShort
        streetAddress
        streetName
        streetNumber
        zoom
      }
      schedule {
        startTime
        label
        endTime
      }
    }
    content
  }
  ${MEDIA_ITEM_FRAGMENT}
  ${SEO_FRAGMENT}
  ${EVENT_FLEXIBLE_CONTENT_FRAGMENT}
  ${EVENT_FEED_FRAGMENT}
`;
