import { useState } from "react";
import { LazyMotion, motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import styles from "./GalleryCarousel.module.css";
const loadFeatures = () =>
  import("utilities/framerFeatures.js").then((res) => res.default);

interface GalleryCarouselProps {
  images: Array<{ src: string; alt: string }>;
}

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      y: 0,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    y: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const GalleryCarousel = ({ images }: GalleryCarouselProps) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, images.length, page);
  const isSingle = images.length === 1;

  const [currentCount, setCurrentCount] = useState(0);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
    setCurrentCount((prevCount) => {
      if (prevCount === images.length - 1 && newDirection === 1) {
        return 0;
      } else if (prevCount === 0 && newDirection === -1) {
        return images.length - 1;
      } else {
        return prevCount + newDirection;
      }
    });
  };

  return (
    <>
      <div className={`mt-4 rounded-xl px-0 md:px-20`}>
        <div className={styles.galleryContainer}>
          <div className={`absolute h-full w-9/12 2xl:1/2 flex flex-row md:block gap-0 overflow-hidden`}>
            <AnimatePresence initial={false} custom={direction}>
              <motion.img
                key={page}
                src={images[imageIndex].src}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x);

                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}
                className={`w-full h-full object-contain absolute`}
              />
            </AnimatePresence>
          </div>
          {!isSingle && (
            <>
              <div className={styles.next} onClick={() => paginate(1)}>
                <i>{"‣"}</i>
              </div>
              <div className={styles.prev} onClick={() => paginate(-1)}>
                <i>{"‣"}</i>
              </div>
              <div className={styles.counter}>
                {currentCount + 1} / {images.length}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GalleryCarousel;
