import { AnimatePresence, m, LazyMotion } from "framer-motion";
import { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { AiOutlineMinus } from "react-icons/ai";

const loadFeatures = () =>
  import("utilities/framerFeatures.js").then((res) => res.default);

export interface BioProps {
  className?: string;
  bio?: string;
  open?: boolean;
  name?: string;
}

const BioFragment = ({ className, bio, open, name }: BioProps) => {
  const [expanded, setExpanded] = useState(false || open);

  return (
    <LazyMotion features={loadFeatures}>
      <m.li
        className={`${className} mx-4 mb-4 flex cursor-pointer flex-col overflow-hidden rounded-xl bg-primary-light p-4 transition-all duration-500 ease-in-out md:mx-auto md:max-w-4xl`}
        onClick={() => setExpanded(!expanded)}
      >
        <m.header className={`flex items-center justify-between`}>
          <h2
            className={`max-w-[90%] font-heading text-lg text-dark md:text-xl`}
          >
            Get to Know {name}
          </h2>
          {expanded ? (
            <AiOutlineMinus className={`w-8 text-primary`} />
          ) : (
            <BsPlusLg className={`w-8 text-primary`} />
          )}
        </m.header>
        <AnimatePresence initial={false}>
          {expanded && bio ? (
            <m.section
              className={`font-body text-lg text-gray-800`}
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <m.div
                variants={{ collapsed: { scale: 0.9 }, open: { scale: 1 } }}
                transition={{ duration: 0.5 }}
              >
                <div
                  className={`mt-4`}
                  dangerouslySetInnerHTML={{ __html: bio }}
                />
              </m.div>
            </m.section>
          ) : null}
        </AnimatePresence>
      </m.li>
    </LazyMotion>
  );
};

export default BioFragment;
