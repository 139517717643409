import { gql } from "@apollo/client";
import * as MENUS from "constants/menus";
import { Layout, Blocks, Loading } from "features";
import { NavigationMenu, Hero, TextBlock } from "components";
import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  SEO_FRAGMENT,
  SEO_CONFIG_FRAGMENT,
  MEDIA_ITEM_FRAGMENT,
  POST_CONTENT_FRAGMENT,
  ANIMALS_FRAGMENT,
} from "fragments";

export default function Component(props) {
  const { data, loading, error } = props;

  if (loading) {
    return <Loading type="page" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const {
    post,
    headerMenuItems,
    footerMenuItems,
    siteSettings,
    seo: defaultSEO,
    animals: { edges: animals },
  } = data;

  const { social } = defaultSEO;

  const {
    seo,
    title,
    featuredImage,
    flexibleContent: { blocks },
    sidebarOptions,
    content,
  } = post;

  const { useSidebar, dogsWidget, donateWidget } =
    sidebarOptions.sidebarOptions;

  const hasBlocks = blocks && blocks.length > 0;
  const {
    address,
    customAddressLabel,
    phoneNumber,
    logo,
    logoWhite,
    logoAlt,
    cta,
    email,
    turnOnAnnouncements,
    announcements,
  } = siteSettings.siteSettings;

  return (
    <Layout
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={seo}
      logo={logo}
      logoWhite={logoWhite}
      logoAlt={logoAlt}
      cta={cta}
      twitterUser={defaultSEO.social.twitter.username}
      address={address}
      customAddressLabel={customAddressLabel}
      phoneNumber={phoneNumber}
      email={email}
      social={social}
      turnOnAnnouncements={turnOnAnnouncements}
      announcements={announcements}
      useSidebar={useSidebar}
      dogsWidget={dogsWidget}
      donateWidget={donateWidget}
      animals={animals}
    >
      {hasBlocks ? (
        <Blocks blocks={blocks} />
      ) : (
        <>
          <Hero
            title={title}
            featuredImage={featuredImage?.node}
            variant={`blogs`}
          />
          {content ? (
            <TextBlock
              paragraphs={[
                {
                  contentAlignment: "left",
                  content: content,
                },
              ]}
              className={`mx-0 text-left`}
            />
          ) : null}
        </>
      )}
    </Layout>
  );
}

Component.query = gql`
  query PostPage(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum!
    $footerLocation: MenuLocationEnum!
    $asPreview: Boolean = false
    $first: Int = 3
  ) {
    generalSettings {
      ...BlogInfoFragment
    }
    siteSettings {
      ...SiteSettingsFragment
    }
    seo {
      ...SEOConfigFragment
    }
    post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      id
      title
      content
      date
      author {
        node {
          name
        }
      }
      flexibleContent {
        ...PostFlexibleContentFragment
      }
      featuredImage {
        node {
          ...MediaItemFragment
        }
      }
      seo {
        ...SEOFragment
      }
      sidebarOptions {
        sidebarOptions {
          dogsWidget
          useSidebar
          donateWidget
        }
      }
    }
    animals(first: $first) {
      ...AnimalsFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${NavigationMenu.fragments.entry}
  ${SEO_FRAGMENT}
  ${SEO_CONFIG_FRAGMENT}
  ${MEDIA_ITEM_FRAGMENT}
  ${POST_CONTENT_FRAGMENT}
  ${ANIMALS_FRAGMENT}
`;

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview,
  };
};
