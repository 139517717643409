import { gql } from "@apollo/client";
import * as MENUS from "constants/menus";
import { Layout, Loading } from "features"; // Blocks eventually
import {
  NavigationMenu,
  GalleryCarousel,
  PostContent,
  VideoGallery,
} from "components";
import { Button } from "components/Button";
import { PetDetails } from "components/PetDetails";
import { PetAttributes } from "components/PetAttributes";
import { SplitText } from "components/SplitText";
import { Accordion } from "components/Accordion";

import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  SEO_FRAGMENT,
  SEO_CONFIG_FRAGMENT,
  MEDIA_ITEM_FRAGMENT,
} from "fragments";
import Paws from "public/text-image-assets/paws.svg";
import getAgeString from "utilities/getAgeString";

const headingIcon = {
  sourceUrl: `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-content/uploads/2023/01/faq.svg`,
  altText: "FAQ Icon",
  caption: null,
  description: null,
  srcSet: null,
  sizes: "(max-width: 300px) 100vw, 300px",
  id: "cG9zdDoxMTgy",
  mediaDetails: {
    width: 120,
    height: 120,
  },
  mimeType: "image/svg+xml",
};

export default function Component(props) {
  const { data, loading, error } = props;

  if (loading) {
    return <Loading type="page" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const {
    cat: animal,
    headerMenuItems,
    footerMenuItems,
    siteSettings,
    seo: defaultSEO,
  } = data;

  const { social } = defaultSEO;

  const {
    seo,
    animalDetails,
    sidebarOptions,
    catAttributes,
    breeds,
    patterns,
    colors,
    catAgeGroups,
    relatedCats,
  } = animal;

  const {
    animalNid: nid,
    animalName: name,
    animalBirthday: birthday,
    animalSex: sex,
    animalWeight: weight,
    animalUniqueId: uniqueId,
    animalPhotoGallery,
    animalVideoGallery,
    animalDescription: description,
    animalCoverPhoto: image,
  } = animalDetails;

  const sourceUrl = image;

  const gallery = animalPhotoGallery?.map((photo, index) => {
    return {
      src: photo.photo,
      alt: `${name} Gallery Photo ${index + 1}`,
    };
  });

  const { useSidebar, dogsWidget, donateWidget } =
    sidebarOptions.sidebarOptions;

  const {
    address,
    customAddressLabel,
    phoneNumber,
    logo,
    logoWhite,
    logoAlt,
    cta,
    email,
    turnOnAnnouncements,
    announcements,
    faqTitle,
    faqItems,
    catFaqItems,
    adoptionRequirements,
    adoptionFees,
  } = siteSettings.siteSettings;
  const splitTextBlock = {
    hasPawIcon: false,
    pawIconPosition: "top-right",
    title: null,
    variant: "basic",
    cardRepeater: [
      {
        content: adoptionRequirements,
        link: null,
        title: "ADOPTION REQUIREMENTS",
        linkType: "primary-outline",
      },
      {
        content: adoptionFees,
        link: null,
        title: "ADOPTION  FEES",
        linkType: "primary-outline",
      },
    ],
  };

  return (
    <Layout
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={seo}
      logo={logo}
      logoWhite={logoWhite}
      logoAlt={logoAlt}
      cta={cta}
      twitterUser={defaultSEO.social.twitter.username}
      address={address}
      customAddressLabel={customAddressLabel}
      phoneNumber={phoneNumber}
      email={email}
      social={social}
      turnOnAnnouncements={turnOnAnnouncements}
      announcements={announcements}
      useSidebar={useSidebar ?? true}
      dogsWidget={dogsWidget ?? false}
      donateWidget={donateWidget ?? true}
      topBorder={true}
      relatedAnimals={relatedCats?.nodes}
      isAnimalSingle
      adoptionLink={`https://www.shelterluv.com/matchme/adopt/GDR-A-${uniqueId}`}
      animalName={name}
      animalId={nid}
      seoImage={sourceUrl}
      isVirtuallyFosterable={false}
    >
      <div className="container relative mx-auto">
        <div className="flex flex-col text-center">
          <h1 className="text-center font-heading text-6xl font-bold">
            {name}
          </h1>
          <p className="text-2xl">#GDR-A-{uniqueId}</p>
        </div>
        <div className={`flex flex-col lg:px-20`}>
          {gallery && <GalleryCarousel images={gallery} />}
          <VideoGallery videos={animalVideoGallery} />
          <PetDetails
            name={name}
            primaryBreed={breeds?.nodes[0]?.name}
            secondaryBreed={``}
            sex={sex}
            weight={weight}
            age={
              birthday
                ? getAgeString(parseInt(birthday))
                : catAgeGroups?.nodes[0]?.name
            }
          />
          {description && <PostContent content={description} />}
          <PetAttributes attributes={catAttributes} />
          <div className="relative my-10 flex flex-col text-center">
            <div
              className={`absolute -top-12 right-0 hidden -scale-x-100 md:flex`}
            >
              <Paws />
            </div>
            <h2 className="z-10 mx-auto max-w-xl text-center font-heading text-6xl font-bold capitalize">
              Ready to meet your new best friend?
            </h2>
            <p className="my-10 text-xl">
              <Button
                href={`https://www.shelterluv.com/matchme/adopt/GDR-A-${uniqueId}`}
                target="_blank"
                variant="primary"
                className="px-8 py-4"
              >
                Apply For Adoption
              </Button>
            </p>
          </div>
          <SplitText {...splitTextBlock} />
          <Accordion
            title={faqTitle}
            itemRepeater={catFaqItems}
            hasHeadingIcon={true}
            headingIcon={headingIcon}
          />
        </div>
      </div>
    </Layout>
  );
}

Component.query = gql`
  query PostPage(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum!
    $footerLocation: MenuLocationEnum!
    $asPreview: Boolean = false
  ) {
    generalSettings {
      ...BlogInfoFragment
    }
    siteSettings {
      ...SiteSettingsFragment
    }
    seo {
      ...SEOConfigFragment
    }
    cat(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      id
      title
      content
      date
      animalDetails {
        animalBirthday
        animalIntakeDate
        animalDescription
        animalName
        animalNid
        animalSex
        animalUniqueId
        animalWeight
        animalPhotoGallery {
          photo
        }
        animalVideoGallery {
          videoId
          embedUrl
          youtubeUrl
          thumbUrl
        }
        animalCoverPhoto
      }
      catAttributes {
        nodes {
          id
          description
          name
          slug
          parent {
            node {
              id
              name
              slug
            }
          }
        }
      }
      breeds {
        nodes {
          id
          description
          name
          slug
          parent {
            node {
              id
              name
              slug
            }
          }
        }
      }
      patterns {
        nodes {
          id
          description
          name
          slug
          parent {
            node {
              id
              name
              slug
            }
          }
        }
      }
      catAgeGroups {
        nodes {
          id
          description
          name
          slug
          parent {
            node {
              id
              name
              slug
            }
          }
        }
      }
      colors {
        nodes {
          id
          description
          name
          slug
          parent {
            node {
              id
              name
              slug
            }
          }
        }
      }
      relatedCats {
        nodes {
          id
          uri
          content
          animalDetails {
            animalBirthday
            animalName
            animalNid
            animalSex
            animalUniqueId
            animalWeight
            animalPhotoGallery {
              photo
            }
            animalCoverPhoto
          }
          breeds {
            nodes {
              id
              description
              name
              slug
            }
          }
          patterns {
            nodes {
              id
              description
              name
              slug
            }
          }
          catAgeGroups {
            nodes {
              id
              description
              name
              slug
            }
          }
          colors {
            nodes {
              id
              description
              name
              slug
            }
          }
          catAttributes {
            nodes {
              id
              description
              name
              slug
            }
          }
        }
      }
      seo {
        ...SEOFragment
      }
      sidebarOptions {
        sidebarOptions {
          dogsWidget
          useSidebar
          donateWidget
        }
      }
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${NavigationMenu.fragments.entry}
  ${SEO_FRAGMENT}
  ${SEO_CONFIG_FRAGMENT}
  ${MEDIA_ITEM_FRAGMENT}
`;

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview,
  };
};
