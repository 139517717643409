import TeamCard from "./Fragments/TeamCard";
import Paws from "public/text-image-assets/paws.svg";
import { Page_Flexiblecontent_Blocks_TeamMembers } from "graphql";
export interface TeamMembersProps
  extends Page_Flexiblecontent_Blocks_TeamMembers {
  className?: string;
}

const TeamMembers = ({
  className,
  members,
  title,
  pawsSide,
  hasUnderline,
}: TeamMembersProps) => {
  const hasMembers = members && members.length > 0;

  const pawsClasses = pawsSide
    ? `-left-24 -top-8 md:-left-12 xl:left-0`
    : `-right-24 -top-8 md:-right-12 xl:right-0 -scale-x-100`;

  return (
    <>
      {hasMembers ? (
        <div className={`${className} relative my-10 px-2`}>
          <h3
            className={`my-4 pb-4 text-center font-heading text-6xl leading-none text-dark`}
          >
            {title}
          </h3>
          <div
            className={`container relative mx-auto flex flex-col md:flex-row md:px-4`}
          >
            <div className={`absolute ${pawsClasses} z-20`}>
              <Paws />
            </div>
            <div
              className={`relative flex flex-row flex-wrap pb-5 md:pb-0 xl:px-20`}
              // grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6
            >
              {members?.map((member, index) => {
                return (
                  <div
                    key={member?.databaseId}
                    className={`w-full p-3 sm:w-1/2 md:w-1/3 xl:p-5`}
                  >
                    <TeamCard member={member} />
                  </div>
                );
              })}
            </div>
          </div>
          {hasUnderline ? (
            <div
              className={`container relative mx-auto my-12 flex h-[1px] w-[65%] flex-col bg-black opacity-75`}
            ></div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default TeamMembers;
