interface SearchProps {
  className?: string;
  handleSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search = ({ className, handleSearch }: SearchProps) => {
  return (
    <div
      className={`relative hidden w-full md:ml-3 lg:ml-0 lg:mr-4 md:block lg:flex-1 xl:ml-14`}
    >
      <input
        type="text"
        placeholder="Search"
        className={className ?? ``}
        onChange={handleSearch}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 24 24"
        className={`opacity-42 pointer-events-none absolute right-3 top-1/2 -translate-y-1/2 transform text-slate-500`}
      >
        <path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" />
      </svg>
    </div>
  );
};

export default Search;
