import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_HtmlBlock } from "graphql";

interface HtmlBlockProps extends Page_Flexiblecontent_Blocks_HtmlBlock {
  className?: string;
}

const HtmlBlock = ({ className, htmlCode }: HtmlBlockProps) => {
  return (
    <section className={`${className ? className : ""} mx-auto max-w-[1300px] py-10`.trim()}>
      {htmlCode && <div dangerouslySetInnerHTML={{ __html: htmlCode }} />}
    </section>
  );
};

export default HtmlBlock;

HtmlBlock.fragments = {
  entry: gql`
    fragment HtmlBlockFragment on Page_Flexiblecontent_Blocks_HtmlBlock {
      __typename
      htmlCode
    }
  `,
};
