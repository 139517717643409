import React from "react";
import styles from "./PostContent.module.css";

interface PostContentProps {
  content: string;
}

function textToHtml(text: string): string {
  let html = text.replace(/\n/g, "<br>");
  // replace *text* to <strong>text</strong>
  html = html.replace(
    /\*(.+?)\*/g,
    '<strong class="font-heading text-2xl">$1</strong>'
  );
  // replace :) with emoji
  html = html.replace(/:\)/g, "😊");
  return `<p>${html}</p>`;
}

const PostContent = ({ content }: PostContentProps) => {
  return (
    <div
      className={styles.body}
      dangerouslySetInnerHTML={{
        __html: textToHtml(content),
      }}
    />
  );
};

export default PostContent;
