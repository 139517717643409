import {
  animate,
  useAnimation,
  useTransform,
  useMotionValue,
  useInView,
  m,
  LazyMotion,
} from "framer-motion";
import { useEffect, useRef } from "react";
import React from "react";
import styles from "../Impact.module.css";
const loadFeatures = () =>
  import("utilities/framerFeatures.js").then((res) => res.default);

interface CounterProps {
  impactNumber: number;
  isAbbreviated: boolean;
  isDollarAmount: boolean;
  variant: string | undefined | null;
}

const Counter = ({
  variant,
  impactNumber,
  isAbbreviated,
  isDollarAmount,
}: CounterProps) => {
  const controls = useAnimation();
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const nodeRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const isInView = useInView(nodeRef, {
    once: true,
  });

  const str = impactNumber.toString();
  const numArray = str.split("");

  const from = 0;
  const to = isAbbreviated ? parseInt(str.slice(0, -3)) : impactNumber;
  const isBig = to.toString().length > 4 ? true : false;
  const speed = isBig ? 1.5 : 0.5;

  useEffect(() => {
    if (isInView) {
      const node = nodeRef.current;

      if (isAbbreviated) {
        const controls = animate(count, to);
      } else {
        const controls = animate(from, to, {
          duration: speed,
          onUpdate(value) {
            let prefix = "";

            if (isBig) {
              if (value < 10000) {
                prefix = "0";
              }
              if (value < 1000) {
                prefix = "00";
              }
              if (value < 100) {
                prefix = "000";
              }
              if (value < 10) {
                prefix = "0000";
              }
            }
            node.textContent = prefix + value.toFixed(0);
          },
        });
      }
    }
    return () => controls.stop();
  }, [count, isBig, speed, isAbbreviated, isInView, from, to]);

  return (
    <>
      {variant === "column" ? (
        <div
          className={`relative ${
            isDollarAmount
              ? "ml-4 pr-[.1em] sm:pr-0 md:ml-8"
              : "pr-[.1em] sm:pr-0"
          }`}
        >
          <div
            ref={nodeRef}
            className={`relative z-10 ${styles.trackingNumbers}`}
          >
            {numArray.map((numA: any, index: number) => (
              <React.Fragment key={index}>{numA}</React.Fragment>
            ))}
          </div>
          <div
            className={`absolute -left-6 top-0 z-0 flex h-full w-full flex-row items-center justify-center ${
              isDollarAmount
                ? "pl-[1.05rem] pr-[0em] sm:pl-4 sm:pr-[.1em] md:pr-[.2em] lg:pl-2 lg:pr-[.3em]"
                : "pl-[1.05rem] pr-[0em] sm:pl-4 sm:pr-[0em] md:pr-[.1em] lg:pl-2 lg:pr-[.2em]"
            }`}
          >
            {numArray.map((_numA: any, index: number) => (
              <div
                key={index}
                className={`${styles.numN} h-[60px] w-[85px] bg-white shadow-md sm:h-[70px] md:h-[90px] lg:h-[130px]`}
              ></div>
            ))}
          </div>
        </div>
      ) : (
        <LazyMotion features={loadFeatures}>
          <m.div ref={nodeRef}>{rounded}</m.div>
        </LazyMotion>
      )}
    </>
  );
};

export default Counter;
