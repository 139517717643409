import { Modal } from "features";
import { ModalProps } from "features/Modal/Modal";
import { AnimatePresence } from "framer-motion";

import { createContext, useState } from "react";

type WithChildrenProps = {
  children: React.ReactNode;
};

export const ModalContext = createContext<
  [(modal: ModalProps) => unknown, () => unknown]
>([() => console.log("Open modal"), () => console.log("Close modal")]);

export function ModalContextProvider({
  children,
}: WithChildrenProps) {
  const [modal, setModal] = useState<ModalProps | undefined>();

  const openModal = (modalProps: ModalProps) => setModal(modalProps);
  const closeModal = () => setModal(undefined);

  return (
    <ModalContext.Provider value={[openModal, closeModal]}>
      {children}
      <AnimatePresence>
        {modal && (
          <Modal
            key="modal"
            {...modal}
          />
        )}
      </AnimatePresence>
    </ModalContext.Provider>
  );
}
