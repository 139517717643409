import { motion } from "framer-motion";
import { ModalBgVariants, ModalBoxVariants } from "./Fragments/variants";

import { ReactNode, useContext } from "react";
import { ModalContext } from "utilities/ModalContextProvider";

export type ModalProps = {
  children?: ReactNode;
};

const Modal = ({ children }: ModalProps) => {
  const [_, closeModal] = useContext(ModalContext);
  return (
    <motion.div
      className={`fixed top-0 z-50 flex h-screen w-full items-center justify-center overflow-scroll bg-[#000000AA]`}
      initial="hidden"
      animate="show"
      exit="hidden"
      variants={ModalBgVariants}
    >
      <motion.div
        className={`relative grid h-fit w-full place-items-center`}
        variants={ModalBoxVariants}
      >
        <button
          className="absolute right-0 top-2 z-50 px-4 font-heading text-3xl text-primary"
          onClick={() => closeModal()}
        >
          X
        </button>
        {children}
      </motion.div>
    </motion.div>
  );
};

export default Modal;
